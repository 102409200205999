import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { SecurityDataService } from 'src/app/services/security/security-data.service';
import { repoApps } from 'src/app/services/shared/apps/core/apps';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  currentCompnent:any
  currentView=''

  constructor(private toastr: ToastrService, private __security:SecurityDataService) {
    this.__security.encrypt('ola')

   }

  ngOnInit(): void {
    this.toastr.success('Hello world!', 'Toastr fun!');
  }
  receiveChildData(data:any){
    this.currentCompnent=repoApps.find(repo=>repo.code==data)?.code


    }
  }


