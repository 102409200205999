

    <!-- begin container-fluid -->
    <div class="container-fluid">
      <!-- begin row -->
      <div class="row">
          <div class="col-md-12 m-b-30">
              <!-- begin page title -->
              <div class="d-block d-lg-flex flex-nowrap align-items-center">
                  <div class="page-title mr-4 pr-4 ">
                      <h4> {{title}}</h4>


                  </div>
                  <div class="ml-auto d-flex align-items-center secondary-menu text-center">






                      <a class="tooltip-wrapper" data-toggle="tooltip" data-placement="top" title="" data-original-title="Listar">
                          <i class="fa fa-list btn btn-icon text-primary" (click)="backToList('classeslist')"></i> </a>
                  </div>
              </div>
              <!-- end page title -->
          </div>
      </div>




      <div class="row formavlidation-wrapper">

          <div class="col-md-12">
              <div class="card card-statistics">
                  <div class="page-title mr-4 pr-4 " style="margin:20px 0px 0px 20px ">
                      <h6 class="text-danger"> Dados Académicos </h6>
                  </div>

                  <div class="card-body">
                      <div class="tab nav-border-bottom">


                          <div class="tab-content">
                              <div class="tab-pane fade py-3 active show" id="home-02" role="tabpanel" aria-labelledby="home-02-tab">
                                  <div id="advanced-first" class="step">
                                      <!-- Step Info -->

                                      <div id="advanced-first" class="step">
                                          <!-- Step Info -->
                                              <div class="row">
                                                  <div class="col-md-12">
                                                       <i class="fa fa-home  institute"></i>
                                                    <p>     <strong>{{classData.institute}}</strong></p>
                                                  </div>
                                                <hr>
                                              </div>
                                          <div class="form-row">

                                              <div class="col-md-3 col-sm-12">
                                                  <div class="form-group">
                                                      <label class="control-label">Nomenclatura*</label>
                                                              <p><strong>{{classData.name}}</strong></p>
                                                                                           </div>
                                              </div>
                                              <div class="col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label class="control-label">Sala*</label>
                                                  <p>  <strong>{{classData.classeroom_name}}</strong></p>
                                                </div>
                                            </div>
                                              <div class="col-md-3 col-sm-12">
                                                  <div class="form-group">
                                                      <label class="control-label">Nível *</label>
                                                      <p><strong>{{classData.level_name}}</strong></p>
                                                  </div>
                                              </div>
                                              <div class="col-md-3 col-sm-12">
                                                  <div class="form-group">
                                                      <label class="control-label">Curso *</label>
                                                       <p><strong>{{classData.course_name}}</strong></p>
                                                  </div>
                                              </div>
                                          </div>
                                          <div class="form-row">

                                              <div class="col-md-3 col-sm-12">
                                                  <div class="form-group">
                                                      <label class="control-label">Ano lectivo *</label>
                                                   <p>   <strong>{{classData.ano_name}}</strong></p>
                                                  </div>
                                              </div>

                                              <div class="col-md-3 col-sm-12">
                                                  <div class="form-group">
                                                      <label class="control-label">Período*</label>

                                                     <p> <strong>{{classData.period}}</strong></p>
                                                  </div>
                                              </div>
                                              <div class="col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label class="control-label">Máximo estudantes:</label>
                                                   <p> <strong>{{classData.max_students}}</strong></p>

                                                </div>
                                            </div>





                                          </div>


                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>

                  </div>
              </div>
          </div>
      </div>


      <div class="row formavlidation-wrapper  ">

          <div class="col-md-12">
              <div class="card card-statistics">
                  <div class="page-title mr-4 pr-4 " style="margin:20px 0px 0px 20px ">
                      <h6 class="text-danger"> Relação Nominal                  <a  class="tooltip-wrapper" data-toggle="tooltip" data-placement="top" title=""
                        data-original-title="Exportar PDF">
                        <i class="fa fa-file-pdf-o btn btn-icon text-cyan" (click)="pdf()"></i></a>
      </h6>
                  </div>
                  <div class="card-body">
                    <div class="  table-responsive">
                      <div class="row float-right p-1">
                        <div class="col-md-12">
                          <input type="text" [(ngModel)]="filternName" class="form-control" name="filter" (keyup)="filter($event)">
                        </div>
                      </div>

                  <table id="listStudents"
                    class="table table-bordered  table-striped table-sm">



                    <thead class="text-light bg-info">
                      <tr>
                        <th class="header" scope="col" width="10%">Ordem</th>
                        <th class="header" scope="col" width="40%">Nome completo</th>

                        <th class="header" scope="col" width="20%">Bilhete</th>
                        <th class="header" scope="col" width="40%">Curso</th>
                        <th class="header" scope="col" width="40%">Nível</th>
                        <th class="header" scope="col" width="40%">Período</th>
                        <th class="header" scope="col" width="40%">Turma</th>
                        <th class="header" scope="col" width="40%">Gênero</th>

                         </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let turma of Students  | tableFilter: filternName;index as i ">
                        <td>{{1+i}}</td>
                        <td>{{turma?.name}}</td>

                        <td>{{turma?.identity_card}}</td>

                        <td>{{turma?.course}}</td>
                        <td>{{turma?._level}}</td>
                        <td>{{turma?.period}}</td>
                        <td>{{turma?.classe}}</td>
                        <td>{{turma?.genre}}</td>
                          </tr>
                    </tbody>
                  </table>
                    <strong>     Total:{{totalRows}} | Page:  {{lastPage}}</strong>
                  <ul class="pagination">

                    <li class="page-item"><a class="page-link" href="#" (click)="previousPage()">Previous</a></li>
                  <!--  <li class="page-item" *ngFor="let pag of [].constructor(totalPages);let i=index"><a class="page-link" href="#" (click)="SetnextPageOrpreviousPage(i)">{{i}}</a></li>
                    -->
                    <li class="page-item"><a class="page-link" href="#" (click)="nextPageOrpreviousPage()">Next</a></li>
                  </ul>
                </div>

              </div>
          </div>
      </div>



  </div>

</div>



