const privateKey=`-----BEGIN PRIVATE KEY-----
MIIJQgIBADANBgkqhkiG9w0BAQEFAASCCSwwggkoAgEAAoICAQC4yEjSgmrWTrSE
nAAg6WM27UJEfOoW6wX1RL/Ubn9xC5lL6V2XkGt3mSk3HIsIX6M6DJb8RVFLUl9h
d0VKrV688KpkGAFRUmwqTacqvb87tiV91Ms7ejSu/dkZc3rtjLDxKXF6wFwh1Q3M
aNa0VEn9r/oINeKhCHBj07z+13nWsnJSUNOhizf2wtiF7MDl8l9zu83jH/opKllm
ooFhLsGvli9HIlxeSNjr6Vh1dHqpMMWufcHljeI/N+2oR3yORWHd8CrCs96CnPD3
pMoqm9zndNgkzy8L+BwDgC36SOtvMJj5MWtkiycUyyix2x22eW4d4rmg9PRMzYLC
lmO5taaoVWokB5DB4JC3DpHNKkxNBmncqjzyZr/cl2Qht5DUccuBiZ+nNa3Qlu4g
RLQLLwStGeEQcsJ+UHMsZHRdn+Sf/JtPXcaYSKFohua5a/DFKAXbryHJI/kRIhbY
8mmmCzPZt2IKF/xywMZ1wJgoFKouOz7ZcqHWTvEETOcLqIRrg67frJzUACgMuNcm
0AFKNBenNPqTO+TqMZTnO/oCL8z8PYLaYizkWk/r+aBPNVbakYF5HgGInn74x0uO
vZK9DxjYgB61OvgMYAO1xzFZrh8dVXMjUu9GyIZY5Y2l89TnwgZ0GjdXufuNCfh4
gxWZtP8lowWoGCqGPdHxJevL7E2q0wIDAQABAoICACpfIvPCnCUQXyyZaR4i5nFN
7ogt7K/crL0VSre3rtmJQ1m45ZE/uKTwJhdRIECHTbKd+HFYo8+Vz/FVnDoHj1Xc
CfZx2rt/HvaEX/TZFeTnW6mDBidZWoNWClZfuKyIOnrSegcf3QCVDlj1IWawfVcC
517CnHk4qiz5/LijSHkG6lgd2KmOdwLKo0PyijkwXtr3EbsnNgICiAWU9f4G7rsI
wUaahnvIUJWoKHSmhQoO+QWOKMwuwNamSITx/4nQNyThUazb+gYRXuAqlYE3xGv+
yyGFsNAZ0LKszd46omIYzrs1rd6cWhXWkDpYeHPDXJDV0KMsCGkkJvvf6HZJRTGn
tqoHikm71KhovXrL+VmTkKzngBtexiouqnIVoqsoRv0+rAHkoDq+chxqdh9V6f0F
El3qASw+P8VRdQX3NRTPplTGkzLxI5FkHe9Y6TgWpoxxL98uGYDegHOD9oD7hluq
LOPldx3axPB+IautOQzvVCw/7Q507Yh/ShaB8+q3Su2qLpDhLkwI5MJNv40wkC+T
GApih1Jwnvuu9ksBbVZx7OWP1TkY9Ez99CyeGpWKmIT6Bb7THfXdtYo3XZejWyJA
AhnLXjhWaRx2GWoDnM8cAg0Y5dXnj7wgTdVedW9PzUl8/40OvbWo7k8sH7Fh5wz/
wQY07M89sPWrpAe3EUphAoIBAQC8D3Ab+yMSesMbtbuEO/qASW/wva5j41U6zhYC
f3SzLemgAirGkVhMdhjJXXENrt5CCNaLgQ0mzr9qCYzkG87TemJ2IGwhq9MC46CI
CNjDC3sR0ibylDmnijnbuv08eDnO7Zm1KkrhRtI7njRrH8FcKPJl+DfVDnTTqcfI
kSq10T9qy0dnt0zUEU0OmoAWH4uxxtJVKK6RfPYTaDk0mCcb3vHZTbZmxDk42Gp2
4/Sxr8c2tdJDrpJIfjyv0Kg1L4IG7h2f5jFkM2ZXmjJ6TGAjIIp2dObDMTV3w6JU
qUFRwlkVRsSFF5hg5/fIIz4iQDx0VbjXRU3uufIgOOHpjDpVAoIBAQD7ibB5yT+T
/Xyc1eBKe1fSvsXZGLwo/k9Ey3RMzTiVFQQW+YhqcQqtUx31Q8PUuU9NU9k1BLEE
6jU4HJngmJLv1oKGEuWwsc9wiSL+m2LBQxkBIUrfaRV8TGZSRRhirMi26HH7MpWu
ht/FQ2AXTRAgjHjvND4GY4YxTuH1Kx25zgF5JNTlUNbwRmAVdDsok1Or9MR4DDZP
u5ucPJPE5LPkU3HBT93IQNkTwT19qWpirhC12SfK0m2E8dg0dvDKDk9myUMWeFeZ
mZ0PaegWeaJnYi6mU0iOmw4IS8qVg9xQ1/I7o1aF/1dICM0kLGMKmH+5C0a2HK7C
yohQJXewJ0iHAoIBACMTvhV6AlCwFTRV8WP4zAChsf91YdG/1WXiirhsjZfeOHVf
8hqXgaDBZl4oz619sHcgruPWbl2OsqVUorLpfOp1KWhfI8wkAHfJfWfiPowi2BG6
4QjeHOLjJWeWuSVOR3oRV1ozSUQ7sXrtR1mz0p0eBhh+8+sJh0Dzqg//A7hmDy4c
PHVb+ziTAYx3xOxbdfLy4sh/jhAU/tEf34uv1ZudOf8OP7drYkD4HOOQIVQDNWU/
BLkbFq3u3eLtSHKjKd2hDGWGyi+mZrBwUnn9RPFIYIs3wtMlmpN8Kgsghs55MaSG
TqGEhSeOHOl3qV86HAyW8KM2ihK9HCxmb7zgKwECggEBAINxjUXm85FbKm4umZly
YQ9g37S150WotzWk9+5WY5YMOGnlFfNBhSnTwFj1g3U7LQSkp2G8RODjntHmqEak
lEw+lVCIuXyfPl55W08fVfjWHk/n3N9oY3Dj36hoMPkHf4+NYlq5MVzVYEQOFD24
6I+JuHyPmUXvZiWIKi3azRrWWxC6PYJjvx4qOHWdmnh2nIfETZLKFuuNhyCay0Fd
nd/X9x7kpGnL8WQQXBi5mj1c5qCQjOnBErghRTENYoDD/5wf2i34zxYWHuv6FO0B
vOs3dFc4ODdFMY0GRiPhlxSr9ApULZeyOiMLbl/TziGwVTYWfWoXDP//pEH1glkL
/isCggEATyaMFwuo67oDSSyrFO84sU2yY97Y9IwLzKENg/HBUS91wPv2SrU89s/+
SYoCAb5aw9bdVEoElKI99lb+PRpEKn77yygRxxH1Ua4HLvGawVzSJLzJPRyXH5lU
lrFtwbzavBLezFl2PSFN4Z9PTV+OFaX3sCRP+L2ex8EQZiUMWsw6+ACrIhRbVJwU
7jSPkhXvD24PPsZr5y0R3X6Xzgmi2A3aHrIVLk0rgkhrQ0Gir2PkaB5j8XMGknZo
5GYWQhs7fpe83pEBxKSN0SpI5/HilIPoy99pypJKeio2sj0Iwb+snzp3Qwhd6XIf
xOColNRfMWT48esXJk7F+JpxTHge9w==
-----END PRIVATE KEY-----
`
const publicKey=`
-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAuMhI0oJq1k60hJwAIOlj
Nu1CRHzqFusF9US/1G5/cQuZS+ldl5Brd5kpNxyLCF+jOgyW/EVRS1JfYXdFSq1e
vPCqZBgBUVJsKk2nKr2/O7YlfdTLO3o0rv3ZGXN67Yyw8SlxesBcIdUNzGjWtFRJ
/a/6CDXioQhwY9O8/td51rJyUlDToYs39sLYhezA5fJfc7vN4x/6KSpZZqKBYS7B
r5YvRyJcXkjY6+lYdXR6qTDFrn3B5Y3iPzftqEd8jkVh3fAqwrPegpzw96TKKpvc
53TYJM8vC/gcA4At+kjrbzCY+TFrZIsnFMsosdsdtnluHeK5oPT0TM2CwpZjubWm
qFVqJAeQweCQtw6RzSpMTQZp3Ko88ma/3JdkIbeQ1HHLgYmfpzWt0JbuIES0Cy8E
rRnhEHLCflBzLGR0XZ/kn/ybT13GmEihaIbmuWvwxSgF268hySP5ESIW2PJppgsz
2bdiChf8csDGdcCYKBSqLjs+2XKh1k7xBEznC6iEa4Ou36yc1AAoDLjXJtABSjQX
pzT6kzvk6jGU5zv6Ai/M/D2C2mIs5FpP6/mgTzVW2pGBeR4BiJ5++MdLjr2SvQ8Y
2IAetTr4DGADtccxWa4fHVVzI1LvRsiGWOWNpfPU58IGdBo3V7n7jQn4eIMVmbT/
JaMFqBgqhj3R8SXry+xNqtMCAwEAAQ==
-----END PUBLIC KEY-----
`
export  {
  publicKey,privateKey
}
