import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-filetr-according',
  templateUrl: './filetr-according.component.html',
  styleUrls: ['./filetr-according.component.css']
})
export class FiletrAccordingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
