import { UpperCasePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'tableFilter'
})
export class TableFilterPipe implements PipeTransform {

  transform(list: any[], value: string) {
    try{
      console.log(value)
  if(value!=''){
     // If there's a value passed (male or female) it will filter the list otherwise it will return the original unfiltered list.
  return   list.filter(file => file.name.indexOf(value) >= 0 ? true : false)
     //return value ? list.filter(item => item.name === value) : list;
}else{
  return list
}
    }catch(ex:any){
      console.log(ex.message)
      return list
    }
  }
}
