import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';
const GROUP_KEY = 'group-user';
const ROLES_KEY = 'roles-user';
@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  constructor(private router:Router) { }
  isLogin=false;
  signOut(): void {
    window.sessionStorage.clear();
    this.router.navigate(['/login'])
  }

  public saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string | null {
    return window.sessionStorage.getItem(TOKEN_KEY);
  }
  public getGroup(): any | null {
    return window.sessionStorage.getItem(GROUP_KEY);

  }
  public getGroupId(): string | null {
    return window.sessionStorage.getItem(GROUP_KEY);

  }

  public checkGroupId(data:any): boolean | null {
    var result=false;
    var id_group=window.sessionStorage.getItem(GROUP_KEY);
     if(data.id_group==id_group){
       result=true;
     }
     return  result;

  }
  public saveUser(user: any): void {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }
  public saveRoles(roles: any): void {
    window.sessionStorage.removeItem(ROLES_KEY);
    window.sessionStorage.setItem(ROLES_KEY, JSON.stringify(roles));
  }
  public saveGroupId(id: number): void {
    window.sessionStorage.removeItem(GROUP_KEY);
    window.sessionStorage.setItem(GROUP_KEY, id.toString());
  }
  public getUser(): any {
    const user = window.sessionStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }

    return {};
  }
  public getRoles(): any {
    const roles = window.sessionStorage.getItem(ROLES_KEY);
    if (roles) {
      return JSON.parse(roles);
    }

    return {};
  }
  public checkToken(): boolean {
    if(window.sessionStorage.getItem(TOKEN_KEY)){
      return true
    }else{
      this.router.navigate(['/login'])
      return false

    }
    return true
  }
}
