import { Injectable, Pipe } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
@Pipe({
  name: 'tableFilter'
})
export class PaginationDataService {

  constructor() { }
  paginate(items:any[],page=1,perPage=100){
    const offset=perPage*(page-1);
    const totalPages=Math.ceil(items.length/perPage);
    const paginateItems=items.slice(offset,perPage*page);
    return{
    previousPage:page - 1 ? page -1:null,
    nextPage:(totalPages > page)?page+1:null,
    total:items.length,
    totalPages:totalPages,
    items:paginateItems
    };


    };
    nextPageOrpreviousPage(items:any[],nextNumber:number){
     return this.paginate(items,nextNumber)
    }
    filterData(data:any[],index:string){
   try{
    return  data.filter(file => file.name.indexOf('admin') >= 0 ? true : false)
   }catch(ex){
    console.log(ex)
    return []
   }
    }
}
