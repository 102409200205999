import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TokenStorageService } from '../../local/token-storage/token-storage.service';
import {environment} from './../../../../environments/environment'


@Injectable({
  providedIn: 'root'
})

export class HttpRepoService {
   token=this.storage.getToken()
  //x-access-token Bearer   Authorization:`Bearer ${token}`,  'x-access-token':`${token}`, },)
 httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json',
    //Authorization:`Bearer ${this.token}`,
  // 'Bearer':`${this.token}`,
  // 'x-access-token':`${this.token}`
  },)
  };
  constructor(private http: HttpClient,private storage:TokenStorageService) { }
 data:any[] = [];
post(data:any,url:string): Observable<any> {
    return this.http.post(environment.baseURL + url,  data

    , this.httpOptions);
  }
  put(data:any,url:string): Observable<any> {
    return this.http.put(environment.baseURL + url, {

     data

    }, this.httpOptions);
  }
  update(data:any,url:string): Observable<any> {
    return this.http.post(environment.baseURL + url, data
    , this.httpOptions);
  }
  get(id: string | number,url:string): Observable<any> {

    return this.http.get(environment.baseURL + url+`${id}`, this.httpOptions);
  }
  delete(id: string | number,url:string): Observable<any> {

    return this.http.delete(environment.baseURL + url+`${id}`, this.httpOptions);
  }
  All(url:string): Observable<any> {

   return this.http.get(environment.baseURL + url, this.httpOptions);
  }

}
