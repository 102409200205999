import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { Classe } from 'src/app/models/edu/classe.model';
import { HttpRepoService } from 'src/app/repository/http/http/http_repo.service';
import { DiaryDataService } from 'src/app/services/base/diary-data.service';
import { periodsData } from 'src/app/static-data/periods.data';


@Component({
  selector: 'app-class-add-update',
  templateUrl: './class-add-update.component.html',
  styleUrls: ['./class-add-update.component.css']
})
export class ClassAddUpdateComponent implements OnInit {

  @Output() currentView : EventEmitter<string> = new EventEmitter()
  @Input() id=''
  title='Novo'

  classForm:Classe={}
  classesNames:any
  levelsNames:any
  courseNames:any
  yearsNames:any
  periodsNames=periodsData
  classromNames:any
dropdownSettings: IDropdownSettings |any
//sletede
selectedClasse_name_id:any

seletedCevelsNames:any
seletedCourseNames:any
seletedYearsNames:any
seletedClassromNames:any
seletedPeriodsNames:any
  constructor(private http:HttpRepoService, private toastr:ToastrService) { }
  ngOnChanges(changes: SimpleChanges): void {
    // idUser.currentValue
  //console.log(changes)

  if(this.id!=''){
    this.getClass()
  }

  }
  ngOnInit(): void {
      this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.getClassesList()
    this.getLevesList()
    this.getCoursesList()
    this.getYearsList()
    this.getClassRoomList()
    this.getMyInstitute()
    if(this.id==''){
      this.getUUID()
      this.classForm.diary=parseInt(DiaryDataService.getDiary())
    }else{
      this.getClass()
    }


   // this.classForm.id=uuid()

  }

backToList(code:string){
    this.currentView.emit(code);


  try{

  }catch(ex){

  }
    //$('#'+ev).toggleClass('show')
   }

  getClassesList(){
    this.http.All("/classnames").subscribe((res)=>{
      this.classesNames=res.data

    },((error)=>{
      if(error.status==401){
        this.toastr.error("Não autorizado!")
      }
     else if(error.status==403){
        this.toastr.error(error.error)
      }
      else{
        this.toastr.error(error.message)

      }    console.log(error)
    }))
  }
  getClassRoomList(){
    this.http.All("/classrooms").subscribe((res)=>{
      this.classromNames=res.data

    },((error)=>{
      if(error.status==401){
        this.toastr.error("Não autorizado!")
      }
     else if(error.status==403){
        this.toastr.error(error.error)
      }
      else{
        this.toastr.error(error.message)

      }    console.log(error)
    }))
  }

 async getClass(){
    this.http.All("/classes/"+this.id).subscribe((res)=>{
      const data=res.data
      delete   data.created_at
      delete     data.updated_at
      this.classForm=data

      //seletedlists
this.refress()

    },((error)=>{
      if(error.status==401){
        this.toastr.error("Não autorizado!")
      }
     else if(error.status==403){
        this.toastr.error(error.error)
      }
      else{
        this.toastr.error(error.message)

      }    console.log(error)
    }))
  }
  save(){

    this.http.post(this.classForm,"/classes").subscribe((res)=>{

  //console.log(res)
  if(res.data){
    this.toastr.success("Salvo com sucesso!")
    this.id=''
    this.classForm={}
    this.backToList('classeslist')
  } else{
    this.toastr.error('Não guardado!')

  }
    },((error)=>{
      if(error.status==401){
        this.toastr.error("Não autorizado!")
      }
     else if(error.status==403){
        this.toastr.error(error.message)
      }
      else{
        this.toastr.error(error.message)

      }    console.log(error)
    }))
  }

  getYearsList(){
    this.http.All("/anosletivos").subscribe((res)=>{
      this.yearsNames=res.data
    },((error)=>{
      if(error.status==401){
        this.toastr.error("Não autorizado!")
      }
     else if(error.status==403){
        this.toastr.error(error.error)
      }
      else{
        this.toastr.error(error.message)

      }    console.log(error)
    }))
  }

getCoursesList(){
  this.http.All("/courses").subscribe((res)=>{
    this.courseNames=res.data

  },((error)=>{
    if(error.status==401){
      this.toastr.error("Não autorizado!")
    }
   else if(error.status==403){
      this.toastr.error(error.error)
    }
    else{
      this.toastr.error(error.message)

    }    console.log(error)
  }))
}

getLevesList(){
  this.http.All("/levels").subscribe((res)=>{
    this.levelsNames=res.data
  },((error)=>{
    if(error.status==401){
      this.toastr.error("Não autorizado!")
    }
   else if(error.status==403){
      this.toastr.error(error.error)
    }
    else{
      this.toastr.error(error.message)

    }    console.log(error)
  }))
}

getUUID(){
  this.http.All("/uuids").subscribe((res)=>{
    this.classForm.id=res.data

  },((error)=>{
    if(error.status==401){
      this.toastr.error("Não autorizado!")
    }
   else if(error.status==403){
      this.toastr.error(error.error)
    }
    else{
      this.toastr.error(error.message)

    }    console.log(error)
  }))
}
getMyInstitute(){
  this.http.All("/institutes/my-institute").subscribe((res)=>{
    this.classForm.institute_id=res.data.id

  },((error)=>{
    if(error.status==401){
      this.toastr.error("Não autorizado!")
    }
   else if(error.status==403){
      this.toastr.error(error.error)
    }
    else{
      this.toastr.error(error.message)

    }    console.log(error)
  }))
}
onSelect(items:any,op:number){
  /*
   course_id?:number ;
    classe_name_id?:number 1;
    classeroom_id?:number 2;
    diary?:number;
    ano_letivo_id?:number;
    level_id?:number 3;
    max_students?:number;
    period?:string;*/
    switch(op){
      case 1:
        this.classForm.classe_name_id=items.id
        break;
        case 2:
          this.classForm.classeroom_id=items.id
          break;
          case 3:
            this.classForm.level_id=items.id
            break;
            case 4:
              this.classForm.course_id=items.id
              break;
              case 5:
                this.classForm.ano_letivo_id=items.id
                break;
                case 6:
                  this.classForm.period=items.name
                  break;
    }
console.log(items)
}
async refress(){

  this.selectedClasse_name_id=this.getSeleted(this.classesNames,this.classForm.classe_name_id,'n')



  this.seletedCevelsNames=this.getSeleted(this.levelsNames,this.classForm.level_id,'n')
  this.seletedCourseNames=this.getSeleted(this.courseNames,this.classForm.course_id,'n')
  this.seletedYearsNames=this.getSeleted(this.yearsNames,this.classForm.ano_letivo_id,'n')
  this.seletedClassromNames=this.getSeleted(this.classromNames,this.classForm.classeroom_id,'n')
  this.seletedPeriodsNames=this.getSeleted(this.periodsNames,this.classForm.period,'s')
}
getSeleted(data:any[],id:string|undefined,skype:string){
  try{
    let seleted:any
    if(skype=='s'){
       seleted= data.find((cn: { name: string | undefined; })=>cn.name== id)
    }else{
       seleted= data.find((cn: { id: string | undefined; })=>cn.id== id)
    }

    //let result={id:seleted.id||'',name:seleted.name||''}

 /*delete seleted.created_at
 delete seleted.diary
 delete seleted.updated_at*/
    console.log(seleted)
 return [ {id:seleted.id,name:seleted.name}]
  }
  catch(ex:any){
    console.log(ex.message)
    return []
  }

}
}
