import { Component, OnInit } from '@angular/core';
import { repoApps } from 'src/app/services/shared/apps/core/apps';

@Component({
  selector: 'app-candidatures',
  templateUrl: './candidatures.component.html',
  styleUrls: ['./candidatures.component.css']
})
export class CandidaturesComponent implements OnInit {
  currentCompnent:any
  currentView=''
  edit=''
  constructor() { }

  ngOnInit(): void {
  }
  receiveChildData(data:any){
    console.log(data)
    if(data.id){
      this.edit=data.id
      this.currentCompnent=repoApps.find(repo=>repo.code==data.code)?.code
    }
    else{
      this.edit=''
      this.currentCompnent=repoApps.find(repo=>repo.code==data)?.code
    }




    }
}
