import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { myApps } from 'src/app/static-data/apps';

@Component({
  selector: 'app-header-dashoar-primary',
  templateUrl: './header-dashoar-primary.component.html',
  styleUrls: ['./header-dashoar-primary.component.scss']
})
export class HeaderDashoarPrimaryComponent implements OnInit {

  apps=myApps

  currentFuntions:any
  currentSubId=0
  @Output() outputFromHeaderCurrentPath : EventEmitter<string> = new EventEmitter()
  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
  }

 actionSubGet(ev:string,id:number,name:string){
  this.outputFromHeaderCurrentPath.emit(name);
  this.currentSubId=id
  console.log(ev)
try{

}catch(ex){

}
  //$('#'+ev).toggleClass('show')
 }
 reload(){
window.location.reload()
 }
 logoff(){
  window.sessionStorage.clear()
  window.location.reload()
 }
}
