

    <!-- begin container-fluid -->
    <div class="container-fluid">
      <!-- begin row -->
      <div class="row">
          <div class="col-md-12 m-b-30">
              <!-- begin page title -->
              <div class="d-block d-lg-flex flex-nowrap align-items-center">
                  <div class="page-title mr-4 pr-4 ">
                      <h4> {{title}}</h4>
                      <p></p>

                  </div>
                  <div class="ml-auto d-flex align-items-center secondary-menu text-center">
                    <a data-toggle="modal"  (click)="save()" class="tooltip-wrapper" title="Salvar">

                      <i class="fa fa-save btn btn-icon text-primary"></i> </a>
                    <a data-toggle="modal"  class="tooltip-wrapper" title="Adicionar">

                          <i class="fa fa-refresh btn btn-icon text-primary" ></i> </a>



                      <a class="tooltip-wrapper" data-toggle="tooltip" data-placement="top" title="" data-original-title="Listar">
                          <i class="fa fa-list btn btn-icon text-primary" (click)="backToList('classeslist')"></i> </a>
                  </div>
              </div>
              <!-- end page title -->
          </div>
      </div>




      <div class="row formavlidation-wrapper">

          <div class="col-md-12">
              <div class="card card-statistics">
                  <div class="page-title mr-4 pr-4 " style="margin:20px 0px 0px 20px ">
                      <h6 class="text-danger"> Preencha os campos </h6>
                  </div>

                  <div class="container">
                    <div class="form-row">
                      <div class="col-md-12 col-sm-12">
                        <div class="form-group">
                            <label class="control-label">Consulte por Bilhete* <i class="fa fa-search btn btn-icon text-primary" (click)="search()"></i></label>
                             <input type="text" class="form-control"  [(ngModel)]="userForm.identity_card" name="userForm.name">

                          </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <div class="form-group">
                          <label class="control-label">Curso *</label>
                          <ng-multiselect-dropdown [placeholder]="' -- seleciona uma opção -- '" [settings]="dropdownSettings"
                          [data]="courseNames"
                          (onSelect)="onSelectCourse($event,1)"
                           >

                         </ng-multiselect-dropdown>
                      </div>
                  </div>
                  <div class="col-md-6 col-sm-12">
                    <div class="form-group">
                        <label class="control-label">Nível *</label>
                        <ng-multiselect-dropdown [placeholder]="' -- seleciona uma opção -- '" [settings]="dropdownSettings"
                        [data]="levelsNames"
                        (onSelect)="onSelectCourse($event,2)"
                        >

                       </ng-multiselect-dropdown>
                    </div>
                </div>
                    <div class="col-md-4 col-sm-12">
                      <div class="form-group">
                          <label class="control-label">Nome Completo*</label>
                          <p><strong>{{userForm.name}}</strong></p>

                          </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div class="form-group">
                        <label class="control-label">Curso*</label>
                        <p> <strong>{{userForm.course}}</strong></p>
                      </div>
                </div>
                <div class="col-md-4 col-sm-12">
                  <div class="form-group">
                      <label class="control-label">Turma*</label>

                    </div>
              </div>
                </div>
                  </div>
                  <div class="card-body" >
                      <div class="tab nav-border-bottom">


                          <div class="tab-content">
                              <div class="tab-pane fade py-3 active show" id="home-02" role="tabpanel" aria-labelledby="home-02-tab">
                                  <div id="advanced-first" class="step">
                                      <!-- Step Info -->

                                      <div id="advanced-first" class="step" *ngIf="!finalizePay">
                                          <!-- Step Info -->
                                          <div class="row">
                                          <div class="col-md-12">
                                            <section style="background-color: #eee;">
                                              <div class="container py-5">
                                                <div class="row">
                                                  <div class="col-md-12 col-lg-4 mb-4 mb-lg-0" *ngFor="let item of produts">
                                                    <div class="card">
                                                      <div class="d-flex justify-content-between p-3">
                                                        <p class="lead mb-0"></p>
                                                        <div
                                                          class="bg-info rounded-circle d-flex align-items-center justify-content-center shadow-1-strong"
                                                          style="width: 35px; height: 35px;">
                                                          <p class="text-white mb-0 small">x4</p>
                                                        </div>
                                                      </div>
                                                      <img src="assets/sales/pngtree-cart-plain-shoping-trolly-icon-vector-illustration-for-web-png-image_1927620.jpg"
                                                        class="card-img-top" alt="Laptop" />
                                                      <div class="card-body">
                                                        <div class="d-flex justify-content-between">
                                                          <p class="small"><a href="#!" class="text-muted">{{item.type}}</a></p>
                                                          <p class="small text-danger"><s>$1099</s></p>
                                                        </div>

                                                        <div class="d-flex justify-content-between mb-3">
                                                          <h5 class="mb-0" style="font-size: 12px;">{{item.name}}</h5>
                                                          <h5 class="text-dark mb-0">{{item.price | number:'1.2-2'}} AO</h5>
                                                        </div>
                                                            <div class="form-row">
                                                              <div class="col-md-12 col-sm-12" *ngIf="monthRequired(item.type)">
                                                                <div class="form-group">
                                                                    <label class="control-label">Mês a pagar*</label>
                                                                    <ng-multiselect-dropdown [placeholder]="' -- seleciona uma opção -- '" [settings]="dropdownSettings"
                                                                    [data]="monthsNames"
                                                                    (onSelect)="onSelect($event,2)">
                                                                   </ng-multiselect-dropdown>
                                                                </div>
                                                            </div>

                                              <div class="col-md-12 col-sm-12" *ngIf="monthRequired(item.type)">
                                                <div class="form-group">
                                                    <label class="control-label">Quantidade *</label>
                                                    <input required="required"  class="form-control" type="number" disabled="" value="1" [(ngModel)]="qtd" name="qtd" required="" placeholder="Quantidade ">

                                                </div>
                                            </div>

                                            <div class="col-md-12 col-sm-12" *ngIf="!monthRequired(item.type)">
                                              <div class="form-group">
                                                  <label class="control-label">Quantidade*</label>
                                                  <input required="required"  class="form-control" type="number" (blur)="getValueForm($event)" required="" placeholder="Quantidade ">

                                              </div>
                                          </div>
                                                            </div>
                                                        <div class="d-flex justify-content-between mb-2">
                                                          <p class="text-muted mb-0">Available: <span class="fw-bold">6</span><i class="fa fa-plus-circle btn btn-icon text-primary" (click)="addItem(item.name,item.id,item.price)"> Adicionar</i></p>
                                                          <div class="ms-auto text-warning">
                                                           <!-- <i class="fa fa-star"></i>
                                                            <i class="fa fa-star"></i>
                                                            <i class="fa fa-star"></i>
                                                            <i class="fa fa-star"></i>
                                                            <i class="fa fa-star"></i>-->
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>

                                                </div>
                                              </div>
                                            </section>

                                            <table
                                            class="table table-bordered  table-striped table-sm">



                                            <thead class="text-light bg-info">
                                              <tr>
                                                <th class="header" scope="col" width="10%">Ordem</th>
                                                <th class="header" scope="col" width="50%">Producto</th>
                                                <th class="header" scope="col" width="10%">Qtd.</th>
                                                <th class="header" scope="col" width="10%">Preço</th>
                                                <th class="header" scope="col" width="10%">Total</th>
                                                <th class="header" scope="col" width="10%">Desconto</th>
                                                <th class="header" scope="col" width="100%">Action</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr *ngFor="let turma of itemSales  ;index as i ">
                                                <td>{{1+i}}</td>
                                                <td>{{turma?.name}}/ {{turma?.month}}</td>
                                                <td>{{turma?.qtd}}</td>
                                                <td>{{turma?.price | number:'1.2-2'}}</td>
                                                <td>{{turma?.total | number:'1.2-2'}}</td>
                                                <td>{{turma?.tax}}</td>
                                                <td><i class="fa fa-eye" ></i> </td>
                                               </tr>
                                            </tbody>
                                          </table>



                                          </div>
                                          <div class="col-md-12" >








                                          </div>
                                          </div>



                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>

                  </div>
              </div>
          </div>
      </div>






      <div class="row formavlidation-wrapper  ">

        <div class="col-md-12">
            <div class="card card-statistics">
                <div class="page-title mr-4 pr-4 " style="margin:20px 0px 0px 20px ">
                    <h6 class="text-danger">  </h6>
                </div>
                <div class="card-body">



                  <div class="container-fluid px-0" id="bg-div" *ngIf="finalizePay" >
                    <div class="row justify-content-center">
                        <div class="col-lg-12 col-12">
                            <div class="card card0">
                                <div class="d-flex" id="wrapper" >
                                    <!-- Sidebar -->
                                    <div class="bg-light border-right" id="sidebar-wrapper" >
                                        <div class="sidebar-heading pt-5 pb-4"><strong>PAY WITH</strong></div>
                                        <div class="list-group list-group-flush"> <a data-toggle="tab" href="#menu1" id="tab1" class="tabs list-group-item bg-light">
                                                <div class="list-div my-2">
                                                    <div class="fa fa-home"></div> &nbsp;&nbsp; Bank
                                                </div>
                                            </a> <a data-toggle="tab" href="#menu2" id="tab2" class="tabs list-group-item active1">
                                                <div class="list-div my-2">
                                                    <div class="fa fa-credit-card"></div> &nbsp;&nbsp; Card
                                                </div>
                                            </a> <a data-toggle="tab" href="#menu3" id="tab3" class="tabs list-group-item bg-light">
                                                <div class="list-div my-2">
                                                    <div class="fa fa-qrcode"></div> &nbsp;&nbsp;&nbsp; Visa QR <span id="new-label">NEW</span>
                                                </div>
                                            </a> </div>
                                    </div> <!-- Page Content -->
                                    <div id="page-content-wrapper">
                                        <div class="row pt-3" id="border-btm">

                                        <div class="row justify-content-center">
                                            <div class="text-center" id="test">Pay</div>
                                        </div>
                                        <div class="tab-content">
                                            <div id="menu1" class="tab-pane">
                                                <div class="row justify-content-center">
                                                    <div class="col-11">
                                                        <div class="form-card">
                                                            <h3 class="mt-0 mb-4 text-center">Enter bank details to pay</h3>
                                                            <form onsubmit="event.preventDefault()">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class="input-group"> <input type="text" id="bk_nm" placeholder="BBB Bank"> <label>BANK NAME</label> </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class="input-group"> <input type="text" name="ben_nm" id="ben-nm" placeholder="John Smith"> <label>BENEFICIARY NAME</label> </div>
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <div class="input-group"> <input type="text" name="scode" placeholder="ABCDAB1S" class="placeicon" minlength="8" maxlength="11"> <label>SWIFT CODE</label> </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-md-12"> <input type="submit" value="Pay $ {{lastSales?.totalPositivo | number:'1.2-2'}}" class="btn btn-success placeicon"> </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-md-12">
                                                                        <p class="text-center mb-5" id="below-btn"><a href="#">Use a test card</a></p>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="menu2" class="tab-pane in active">
                                                <div class="row justify-content-center">
                                                    <div class="container">
                                                      <div class="page-title mr-4 pr-4 " style="margin:20px 0px 0px 20px ">
                                                        <h6 class="text-danger"> Finalizar pagamento </h6>
                                                    </div>



                                                    <div class="card-body">


                                                      <div class="form-row">
                                                        <div class="col-md-12 col-sm-12">
                                                          <div class="form-group">
                                                              <label class="control-label">Valor recebido * <i class="fa fa-credit-card btn btn-icon text-primary"></i></label>
                                                               <input type="number" class="form-control"  [(ngModel)]="endDoc.totalReceived" name="endDoc.totalReceived">

                                                            </div>
                                                      </div>
                                                      <div class="col-md-12 col-sm-12">
                                                        <div class="form-group">
                                                            <label class="control-label">Documento Bancário * <i class="fa fa-credit-card btn btn-icon text-primary"></i></label>
                                                             <input type="text" class="form-control"  [(ngModel)]="endDoc.Docnumber" name="endDoc.Docnumber">

                                                          </div>
                                                    </div>

                                                    <div class="col-md-12 col-sm-12">
                                                      <div class="form-group">
                                                          <label class="control-label">Data Pagamento * <i class="fa fa-credit-card btn btn-icon text-primary"></i></label>
                                                           <input type="date" class="form-control"  [(ngModel)]="endDoc.date_pay" name="endDoc.date_pay">

                                                        </div>
                                                  </div>
                                                    <div class="col-md-12 col-sm-12">
                                                      <div class="form-group">
                                                           <button class="btn btn-success" (click)="finalizeDoc()">Pagar ${{lastSales?.totalPositivo | number:'1.2-2'}}</button>
                                                        </div>
                                                  </div>



                                                  </div>

                                              </div>








                </div>
                </div>



                </div>
                </div>
                </div>



