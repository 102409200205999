import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpRepoService } from 'src/app/repository/http/http/http_repo.service';

@Component({
  selector: 'app-candidatures-details',
  templateUrl: './candidatures-details.component.html',
  styleUrls: ['./candidatures-details.component.css']
})
export class CandidaturesDetailsComponent implements OnInit {

  title='Ficha do estudante'
  @Input() id=''
  @Output() currentView : EventEmitter<string> = new EventEmitter()
  userData:any
  constructor(private http:HttpRepoService,private toastr:ToastrService) { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    // idUser.currentValue
  //console.log(changes)


    this.getClass()


  }
  async getClass(){
    this.http.All("/candidatures/"+this.id).subscribe((res)=>{
        console.log(res.data)
      this.userData=res.data

      //seletedlists


    },((error)=>{
      if(error.status==401){
        this.toastr.error("Não autorizado!")
      }
     else if(error.status==403){
        this.toastr.error(error.error)
      }
      else{
        this.toastr.error(error.message)

      }    console.log(error)
    }))
  }

  backToList(code:string){
    this.currentView.emit(code);


  try{

  }catch(ex){

  }
    //$('#'+ev).toggleClass('show')
   }



}
