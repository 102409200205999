import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HeaderDashoarPrimaryComponent } from './shared/layout/primary/header-dashoar-primary/header-dashoar-primary.component';
import { NavBarDashboardPrimaryComponent } from './shared/layout/primary/nav-bar-dashboard-primary/nav-bar-dashboard-primary.component';
import { MainDashboardPrimaryComponent } from './shared/layout/primary/main-dashboard-primary/main-dashboard-primary.component';
import { DataTablesModule } from "angular-datatables";
import { ToastrModule } from 'ngx-toastr';
import { UsersComponent } from './components/apps/system/users/users.component';
import { UserDetailsComponent } from './components/apps/system/users/user-details/user-details.component';
import { UserAddUpdateComponent } from './components/apps/system/users/user-add-update/user-add-update.component';
import { ModalContainerComponent } from './shared/layout/primary/modal-container/modal-container.component';
import { FiletrAccordingComponent } from './shared/layout/primary/filetr-according/filetr-according.component';
import { TableContainerComponent } from './shared/layout/primary/table-container/table-container.component';
import { LoginComponent } from './components/apps/system/auth/login/login.component';
import { BootComponent } from './components/apps/system/boot/boot.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FooterPrimaryComponent } from './shared/layout/primary/footer-primary/footer-primary.component';
import { UsersListComponent } from './components/apps/system/users/users-list/users-list.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppContainerOpenComponent } from './shared/layout/primary/app-container-open/app-container-open.component';
import { AppContainerAppsComponent } from './shared/layout/primary/app-container-apps/app-container-apps.component';
import { TableFilterPipe } from './pipes/fiterlTable';
import { ClassesComponent } from './components/apps/app/edu/classes/classes.component';
import { ClassesListComponent } from './components/apps/app/edu/classes/classes-list/classes-list.component';
import { ClassesDetailsComponent } from './components/apps/app/edu/classes/classes-details/classes-details.component';
import { ClassAddUpdateComponent } from './components/apps/app/edu/classes/class-add-update/class-add-update.component';
import { StudentsComponent } from './components/apps/app/edu/students/students/students.component';
import { StudentsListComponent } from './components/apps/app/edu/students/students-list/students-list.component';
import { StudentsAddUpdateComponent } from './components/apps/app/edu/students/students-add-update/students-add-update.component';
import { StudentsDetailsComponent } from './components/apps/app/edu/students/students-details/students-details.component';
import { StudentsFirstRegisterComponent } from './components/apps/app/edu/students/students-first-register/students-first-register.component';
import { AuthInterceptor } from './repository/auth-interceptor';
import { SubjectsComponent } from './components/apps/app/edu/subjects/subjects/subjects.component';
import { SubjectsListComponent } from './components/apps/app/edu/subjects/subjects-list/subjects-list.component';
import { SubjectsAddUpdateComponent } from './components/apps/app/edu/subjects/subjects-add-update/subjects-add-update.component';
import { SubjectsDetailsComponent } from './components/apps/app/edu/subjects/subjects-details/subjects-details.component';
import { DefaultDashboardComponent } from './components/apps/app/edu/dashboard/default-dashboard/default-dashboard.component';
import { AddUpdateCurricularPlanComponent } from './components/apps/app/edu/curricularPlan/add-update-curricular-plan/add-update-curricular-plan.component';
import { CurricularPlanComponent } from './components/apps/app/edu/curricularPlan/curricular-plan/curricular-plan.component';
import { CurricularPlanListComponent } from './components/apps/app/edu/curricularPlan/curricular-plan-list/curricular-plan-list.component';
import { CurricularPlanDetailsComponent } from './components/apps/app/edu/curricularPlan/curricular-plan-details/curricular-plan-details.component';
import { MainWin11Component } from './shared/layout/theme-w11/main-win11/main-win11.component';
import { FooterWin11Component } from './shared/layout/theme-w11/footer-win11/footer-win11.component';
import { AppContainerOpenWin11Component } from './shared/layout/theme-w11/app-container-open-win11/app-container-open-win11.component';
import { AppModalWin11Component } from './shared/layout/theme-w11/app-modal-win11/app-modal-win11.component';
import { CandidaturesComponent } from './components/apps/app/edu/candidatures/candidatures/candidatures.component';
import { CandidaturesListComponent } from './components/apps/app/edu/candidatures/candidatures-list/candidatures-list.component';
import { CandidaturesDetailsComponent } from './components/apps/app/edu/candidatures/candidatures-details/candidatures-details.component';
import { CandidaturesAddUpdateComponent } from './components/apps/app/edu/candidatures/candidatures-add-update/candidatures-add-update.component';
import { AddUpdatePaymentComponent } from './components/apps/app/sales/payments/add-update-payment/add-update-payment.component';
import { PaymentListComponent } from './components/apps/app/sales/payments/payment-list/payment-list.component';
import { PaymentDetailsComponent } from './components/apps/app/sales/payments/payment-details/payment-details.component';
import { PaymentsComponent } from './components/apps/app/sales/payments/payments/payments.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderDashoarPrimaryComponent,
    NavBarDashboardPrimaryComponent,
    MainDashboardPrimaryComponent,
    UsersComponent,
    UserDetailsComponent,
    UserAddUpdateComponent,
    ModalContainerComponent,
    FiletrAccordingComponent,
    TableContainerComponent,
    LoginComponent,
    BootComponent,
    FooterPrimaryComponent,
    UsersListComponent,
    AppContainerOpenComponent,
    AppContainerAppsComponent,
    TableFilterPipe,
    ClassesComponent,
    ClassesListComponent,
    ClassesDetailsComponent,
    ClassAddUpdateComponent,
    StudentsComponent,
    StudentsListComponent,
    StudentsAddUpdateComponent,
    StudentsDetailsComponent,
    StudentsFirstRegisterComponent,
    SubjectsComponent,
    SubjectsListComponent,
    SubjectsAddUpdateComponent,
    SubjectsDetailsComponent,
    DefaultDashboardComponent,
    AddUpdateCurricularPlanComponent,
    CurricularPlanComponent,
    CurricularPlanListComponent,
    CurricularPlanDetailsComponent,
    MainWin11Component,
    FooterWin11Component,
    AppContainerOpenWin11Component,
    AppModalWin11Component,
    CandidaturesComponent,
    CandidaturesListComponent,
    CandidaturesDetailsComponent,
    CandidaturesAddUpdateComponent,
    AddUpdatePaymentComponent,
    PaymentListComponent,
    PaymentDetailsComponent,
    PaymentsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DataTablesModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    NgMultiSelectDropDownModule.forRoot(),
    FormsModule,
    HttpClientModule

  ],
  providers: [ {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
