import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DiaryDataService {

  constructor() { }
 public static getDiary(){
    let data1=new Date()
    let appData=data1.toISOString()
    appData=appData.split('T')[0].toString()
    let newData=appData.split('-')
    let diario=`${newData[0]}${newData[1]}${newData[2]}`
      return diario

  }
}
