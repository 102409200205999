import { Component, EventEmitter, OnInit, Output, Pipe, PipeTransform, ViewChild } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { HttpRepoService } from 'src/app/repository/http/http/http_repo.service';
import { PaginationDataService } from 'src/app/services/base/pagination-data.service';
import { SecurityDataService } from 'src/app/services/security/security-data.service';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.css']
})

export class UsersListComponent implements OnInit {
  @Output() currentView : EventEmitter<string> = new EventEmitter()
  @Output() user_id : EventEmitter<string> = new EventEmitter()
  @Pipe({
    name: 'tableFilter'
  })
   currentCompnent='Utilizadores'
filternName=''
  users:any
  usersBruteData:any
pages:any
totalPages=0
pageNumber=0
totalRows=0
lastPage=1
name: any;
  constructor(private toastr: ToastrService, private __security:SecurityDataService,private http:HttpRepoService,private paginate:PaginationDataService) {
    this.__security.encrypt('ola')

   }

  ngOnInit(): void {
   this. getUsersData()

  }



  addUser(code:string){
    this.currentView.emit(code);


  try{

  }catch(ex){

  }
    //$('#'+ev).toggleClass('show')
   }
   sendId(id:string,code:string){
    this.user_id.emit(id);
    this.currentView.emit(code);

  try{

  }catch(ex){

  }
    //$('#'+ev).toggleClass('show')
   }
   private getUsersData() {
    try{
      this.http.All('/users')
      .subscribe((res)=>{
        console.log(res.error)

          if(res.data.length>0){

            this.usersBruteData =res.data
            this.pages=this.paginate.paginate( this.usersBruteData)
            this.users=this.pages.items
            this.totalRows=this.pages.total
            this.totalPages= this.pages.totalPages
            console.log(this.paginate.paginate( this.usersBruteData))
/* var value = $(this).val().toLowerCase();
    $("#myList li").filter(function() {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
    });*/

          }
          else{}
      //  this.topRightToast('Sem dados!')
      });

    }catch(ex:any){

    this.toastr.error("Erro")

    }


  }
  nextPageOrpreviousPage(){

    this.pages=this.paginate.nextPageOrpreviousPage(this.usersBruteData,this.pages.nextPage)
    this.users=this.pages.items
    this.lastPage=this.pages.nextPage
  }
  previousPage(){
    if(this.lastPage>1){
      this.lastPage= this.lastPage-1
    }


    this.pages=this.paginate.nextPageOrpreviousPage(this.usersBruteData,this.lastPage)
    this.users=this.pages.items
  }
  SetnextPageOrpreviousPage(_pag=0){

    this.pages=this.paginate.nextPageOrpreviousPage(this.usersBruteData,_pag)
    this.users=this.pages.items
    console.log( this.users)
  }
  filter(ev:any){
    console.log(ev.target.value)
let data =this.paginate.paginate(this.paginate.filterData(this.usersBruteData,ev.target.value))

//this.users= data.items
  }

}

