import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpRepoService } from 'src/app/repository/http/http/http_repo.service';
import { TokenStorageService } from 'src/app/repository/local/token-storage/token-storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  formLogin={
    username:'',
    password:''
  }
  constructor(private storage:TokenStorageService,private http:HttpRepoService,private toastr:ToastrService) { }

  ngOnInit(): void {
  }

  submit(){
this.login()
  }
private login(){
  this.http.post(this.formLogin,"/auth/login").subscribe(res=>{
 if(res.token){
  this.toastr.success("Bem vindo!!!")
  this.storage.saveToken(res.token)
  window.location.reload()
 }
  },(error=>{
    console.log(error)
    this.toastr.error("Não permitido!!!")
  }));

}
}
