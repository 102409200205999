

    <!-- begin container-fluid -->
    <div class="container-fluid">
      <!-- begin row -->
      <div class="row">
          <div class="col-md-12 m-b-30">
              <!-- begin page title -->
              <div class="d-block d-lg-flex flex-nowrap align-items-center">
                  <div class="page-title mr-4 pr-4 ">
                      <h4> {{title}}</h4>
                      <p></p>

                  </div>
                  <div class="ml-auto d-flex align-items-center secondary-menu text-center">
                    <a data-toggle="modal"  (click)="save()" class="tooltip-wrapper" title="Salvar">

                      <i class="fa fa-save btn btn-icon text-primary"></i> </a>
                    <a data-toggle="modal"  class="tooltip-wrapper" title="Adicionar">

                          <i class="fa fa-refresh btn btn-icon text-primary" ></i> </a>



                      <a class="tooltip-wrapper" data-toggle="tooltip" data-placement="top" title="" data-original-title="Listar">
                          <i class="fa fa-list btn btn-icon text-primary" (click)="backToList('classeslist')"></i> </a>
                  </div>
              </div>
              <!-- end page title -->
          </div>
      </div>




      <div class="row formavlidation-wrapper">

          <div class="col-md-12">
              <div class="card card-statistics">
                  <div class="page-title mr-4 pr-4 " style="margin:20px 0px 0px 20px ">
                      <h6 class="text-danger"> Preencha os campos </h6>
                  </div>

                  <div class="card-body">
                      <div class="tab nav-border-bottom">


                          <div class="tab-content">
                              <div class="tab-pane fade py-3 active show" id="home-02" role="tabpanel" aria-labelledby="home-02-tab">
                                  <div id="advanced-first" class="step">
                                      <!-- Step Info -->

                                      <div id="advanced-first" class="step">
                                          <!-- Step Info -->

                                          <div class="form-row">

                                              <div class="col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label class="control-label">Disciplina *</label>
                                                    <input required="required"  class="form-control" type="text" [(ngModel)]="subjectForm.name" name="subjectForm.max_students" required="" placeholder="Nome da disciplina ">

                                                </div>
                                            </div>





                                          </div>


                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>

                  </div>
              </div>
          </div>
      </div>


      <div class="row formavlidation-wrapper  ">

          <div class="col-md-12">
              <div class="card card-statistics">
                  <div class="page-title mr-4 pr-4 " style="margin:20px 0px 0px 20px ">
                      <h6 class="text-danger"> Preencha os campos </h6>
                  </div>
                  <div class="card-body">


                      <div class="form-row">



                  </div>

              </div>
          </div>
      </div>



  </div>

</div>



