import { Injectable } from '@angular/core';
import * as JsEncryptModule from 'jsencrypt';
import { publicKey, privateKey } from 'src/app/config';

@Injectable({
  providedIn: 'root'
})
export class SecurityDataService {
   encryptMod: any;
  constructor() {
    this.encryptMod = new JsEncryptModule.JSEncrypt();
   }

	encrypt(plainText:string) {

    this.encryptMod.setPublicKey(publicKey);
    let cypherText = this.encryptMod.encrypt(plainText);
    console.log("encryptedText-",cypherText);


}

decrypt(cypherText:string) {
  this.encryptMod.setPrivateKey(privateKey);
  let plainText = this.encryptMod.decrypt(cypherText);
  console.log("decryptedText-",plainText);
}
}
