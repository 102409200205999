import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-students-first-register',
  templateUrl: './students-first-register.component.html',
  styleUrls: ['./students-first-register.component.css']
})
export class StudentsFirstRegisterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
