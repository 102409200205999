
<style>

  .search-app{
    border-radius: 20px;
    border: none;
    background: rgb(18, 18, 63);
    text-align: center;
    color: aliceblue;
  }
  input{
  border:#ddd solid 1px;
  float:left;
  padding:5px 20px 5px 5px;

  }

  /* formatação do elemento */
  #lupa{
  cursor:pointer
  }

  /* formatação do conteúdo  */
  #lupa:after{
  font-family:FontAwesome;
  font-size:11px;
  content:"fa fa-search";
  color: aliceblue;

  }
  </style>

<nav class="navbar navbar-expand-sm " style="background: none; margin-top: -10px;">
  <!-- Brand -->
  <a class="navbar-brand" href="#" data-toggle="modal" data-target="#modal-app2"><img src="assets/logo.png" width="30" alt=""></a>
  <div class="input-group">


    <div class="input-group-append ">
      <span class="input-group-text search-app">
        <input type="text" class="search-app" id="lupa">
        <i class="fa fa-search" role="lupa"> Pesquisar </i></span>


    </div>
  </div>

  <!-- Links -->
  <ul class="navbar-nav">

<li class="nav-item" *ngFor="let app of apps" (click)="sendID(app.id)"><img data-toggle="modal" data-target="#modal-app" src="./assets/layout-mac/images/apps/{{app.id}}.png" alt="Appstore" title="{{app.name}}" width="40"></li>


  </ul>
</nav>









