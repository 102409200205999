import { style } from '@angular/animations';
import { Injectable } from '@angular/core';
//import { Workbook } from 'exceljs';
//import * as FileSaver from 'file-saver';
 //const { decode }= require ('image-data-uri');

@Injectable({
  providedIn: 'root'
})
export class ExcelReportsService {

  constructor() { }
  gerarExcel(dados: any[], nomeArquivo: string,header_logo:any) {
  //  const workbook = new Workbook();
    //const worksheet = workbook.addWorksheet('Dados');
   // const imageData = decode(header_logo);
  //  const imageBuffer = Buffer.from(header_logo);
    //const imageBuffer = new Uint8Array(header_logo);
    // Adicionar imagem
    /*
worksheet.addImage({
  buffer: imageBuffer,
  extension: 'png',
  tl: { col: 1, row: 2 },
  ext: { width: 200, height: 200 }
});*/
/*
const hd=workbook.addImage({ buffer: imageBuffer, extension: 'jpeg' });

worksheet.addImage(hd, 'B1:E10');
worksheet.mergeCells('B1:E10');
    // Cabeçalho
    let header=['Nome completo','Nº Bilhete','Idade', 'Curso', 'Nível','Período','Turma','Gênero']

    //const headerRow =  worksheet.addRow(['Nome', 'Idade', 'Email']);
    const headerRow = worksheet.addRow(header);

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: '51944C' }
      };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    });

    const colName = worksheet.getColumn(1);
    const colCard = worksheet.getColumn(2);
    const colIdade = worksheet.getColumn(3);
    const colICourse = worksheet.getColumn(4);
    const colLevel= worksheet.getColumn(5);
//column.header = 'Header 1';   name,identity_card,idade,course,_level,period,classe,,genre
colName.width = 40;
colIdade.width = 8
colCard.width = 17;
colICourse.width=26
colLevel.width=22


    // worksheet.addRows(data);


    // Aplicar formatação


    // Dados
    dados.forEach(dado => {

      worksheet.addRow([dado.name,dado.identity_card, dado.idade, dado.course,dado._level, dado.period, dado.classe,dado.genre]);
    });

    // Salvar arquivo
    workbook.xlsx.writeBuffer().then(buffer => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      FileSaver.saveAs(blob, `${nomeArquivo}.xlsx`);
    });*/
  }

}
