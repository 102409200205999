import { Component, ComponentFactoryResolver, ComponentRef, ElementRef, Injector, NgModuleFactory, NgModuleRef, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import * as JsEncryptModule from 'jsencrypt';
import { privateKey, publicKey } from './config';
import { repoApps } from './services/shared/apps/core/apps';
import {  IDropdownSettings } from 'ng-multiselect-dropdown';
import { myApps } from './static-data/apps';
import { TokenStorageService } from './repository/local/token-storage/token-storage.service';
import { HttpRepoService } from './repository/http/http/http_repo.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  dropdownList = repoApps
  listDepartments=myApps
  selectDepartments=[]
  selectedItems = [

  ];

  dropdownSettings: IDropdownSettings |any
  title = 'dashboard';
  col_10='col-md-12'
  col_2='col-md-0'
 modal_dialogo_full= 'modal-dialog-full'
 modal_content_full= 'modal-content-full'
 modal_body_full= ' modal-body-full'
    result=''
    matriculas:any
   plainText: string = 'testing encryption';
	 cypherText: string = '';

	 encryptMod: any;


  name = 'Angular';
  theme:Component | any
themes:any
 ngComponentOutletNgModuleFactory!: NgModuleFactory<any>
private _componentRef: ComponentRef<any>|null = null;
private _moduleRef: NgModuleRef<any>|null = null;
ngComponentOutletInjector!: Injector;
currenttTheme:any
constructor(private elementRef: ElementRef,private _viewContainerRef: ViewContainerRef,private storage:TokenStorageService,private http:HttpRepoService){
  this.encryptMod = new JsEncryptModule.JSEncrypt();

}

loadJS(p:string){
  var s = document.createElement("script");
  s.type = "text/javascript";
  s.src = p
  this.elementRef.nativeElement.appendChild(s);
}
  ngOnInit(): void {
    this.loadJS("./assets/layout-mac/lib/jquery-3.5.1.min.js")
this.loadJS("./assets/layout-mac/lib/jquery.resizeOnApproach.1.0.min.js")
this.loadJS("./assets/layout-mac/lib/jquery-ui.js")
this.loadJS("./assets/layout-mac/index.js")
      this.themes=repoApps
      this.theme=this.themes[5].component
      this.loading()
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    //this.encrypt();
    this.result=this.cypherText
    console.log(this.cypherText);

  }
  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  ngOnChanges(changes: SimpleChanges) {
    this._viewContainerRef.clear();
    this._componentRef = null;

    if (this.theme) {
      const elInjector = this.ngComponentOutletInjector || this._viewContainerRef.parentInjector;

      if (changes['ngComponentOutletNgModuleFactory']) {
        if (this._moduleRef) this._moduleRef.destroy();

        if (this.ngComponentOutletNgModuleFactory) {
          const parentModule = elInjector.get(NgModuleRef);
          this._moduleRef = this.ngComponentOutletNgModuleFactory.create(parentModule.injector);
        } else {
          this._moduleRef = null;
        }
      }

      const componentFactoryResolver = this._moduleRef ? this._moduleRef.componentFactoryResolver :
                                                         elInjector.get(ComponentFactoryResolver);

      const componentFactory =
          componentFactoryResolver.resolveComponentFactory(this.theme);

      this._componentRef = this._viewContainerRef.createComponent(
          componentFactory, this._viewContainerRef.length, elInjector,
          this.theme);
    }
  }

  ngOnDestroy() {
    if (this._moduleRef) this._moduleRef.destroy();
  }
change(){
if(this.storage.checkToken()){
  this.getCurrentTheme()

 // this.theme=this.themes[0].component
}
else{
  this.theme=this.themes[4].component
}

}
 change1(){

}
  loading(){
    setTimeout(()=>{                           // <<<---using ()=> syntax
      this.change()
  }, 15000);
}
getCurrentTheme(){
  this.http.All('/servicessystem/ative').subscribe(res=>{
if(res.data){
  this.currenttTheme=res.data
  this.theme=this.themes.find((t: { code: any; })=>t.code==this.currenttTheme.code).component
console.log(this.theme)

}
  },error=>{

  })
}
}
