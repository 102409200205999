import { Component, OnInit } from '@angular/core';
import User from 'src/app/models/user';

@Component({
  selector: 'app-user-add-update',
  templateUrl: './user-add-update.component.html',
  styleUrls: ['./user-add-update.component.css']
})
export class UserAddUpdateComponent implements OnInit {
  matricula:any
  title='Novo'
  userForm:User={

  }
  constructor() { }

  ngOnInit(): void {
  }

}
