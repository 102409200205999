import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

@Injectable({
  providedIn: 'root'
})
export class PdfWithObjectService {

  //doc = new jsPDF()
  constructor() { }
  pdf(head:any,data:any,tag:string,header_logo:any){

   //autoTable(this.doc, { html: tag })
/*
   let finalY=0
   autoTable(this.doc,{
     startY: finalY + 20,
     head: [column],
     body:
      data
     ,
     styles:{
      overflow:"linebreak"
     }
   })

//this.doc.addPage()
   autoTable(this.doc,{
    startY: finalY + 28,
    head: [column],
    body:
     data
    ,
    styles:{
      overflow:"linebreak"
     }
  })*/

  // this.doc.save('table.pdf')
  //var base64data = new Buffer(header_logo).toString('base64');
  const doc = new jsPDF('l', 'mm', 'a4');
  const buffer = new Uint8Array(header_logo);
  doc.addImage(buffer,'JPEG',60,40,200,45)
  var header = function(data: { settings: { margin: { left: number; }; }; }) {
    doc.setFontSize(18);
    doc.setTextColor(40);
    //doc.setFontStyle('normal');
    doc.text("Header Title", data.settings.margin.left, 50);
  };
/*
  var options = {
    didDrawPage: header,
    margin: {
      top: 80
    },
    startY: 60
  };*
  */
 let Settings= (data: { settings: { margin: { left: number; }; }; }) => {

  // Header
  doc.setFontSize(20);
  doc.setTextColor(40);
  //doc.text("Report", data.settings.margin.left, 22);

  // Footer
  var str = "Page " +  (doc as any).internal.getNumberOfPages();

  doc.setFontSize(10);

  // jsPDF 1.4+ uses getWidth, <1.4 uses .width
  var pageSize = doc.internal.pageSize;
  var pageHeight = pageSize.height
    ? pageSize.height
    : pageSize.getHeight();
  doc.text(str, data.settings.margin.left, pageHeight - 10);

      }

///let finalY=autoTable.previous.finalY
    autoTable(doc, {
      startY: 100,

        head: head.headerList,
        body: data.resultList,
        didDrawCell:Settings

    });

    autoTable(doc, {

      head: head.headerByClasses ,
      body: data.listByClasses,
      didDrawCell:Settings


  });


    doc.save('table.pdf');
}

getByClasse(data:any){



  return {resultListresumeClass:data.reduce((acc: { classe: any; _level: any; course: any; total: number; }[], obj: { classe: any; _level: any; course: any; }) => {
      const found = acc.find((x: { classe: any; _level: any; }) => x.classe === obj.classe && x._level === obj._level);
      if (found) {
          found.total++;
      } else {
          acc.push({ classe: obj.classe, _level: obj._level, course: obj.course, total: 1 });
      }
      return acc;
  }, []).map((m: { classe: string; _level: string; course: string; total: number; })=>[m.classe,m._level,m.course,m.total]),
  headerListresumeClass:[['Turma','Classe','Curso','Total']]
  }

  }


  getByClassePeriod(data:any){

return {listByClasses:data.reduce((acc: { classe: any; course: any; _level: any; period: any; total: number; }[], obj: { classe: any; course: any; _level: any; period: any; }) => {
  const found = acc.find((x: { classe: any; course: any; _level: any; period: any; }) => x.classe === obj.classe && x.course === obj.course && x._level === obj._level && x.period === obj.period);
  if (found) {
      found.total++;
  } else {
      acc.push({ classe: obj.classe, course: obj.course, _level: obj._level, period: obj.period, total: 1 });
  }
  return acc;
}, []).sort((a: { _level: number; }, b: { _level: number; }) => (a._level > b._level ? 1 : -1))
.map((m: { classe: string; course: string; _level: string; period: string;total:number; })=>[m.classe,m.course,m._level,m.period,m.total])
,headerByClasses:[['Classe','Curso','Frequencia','Período','Total Estudantes']]}

    }
}
