
<style>
section {
 background: #111 url("assets/boot.png");
 background-size: 80vmin;
 background-repeat: no-repeat;
 background-position: center 40%;
 height: 120vh;
 margin: 0;
}
.loading {
 width: 400px;
 max-width: 85vw;
 height: 4px;
 position: absolute;
 bottom: 20vh;
 left: 50%;
 border-radius: 4px;
 background: rgba(255,255,255,0.5);
 transform: translate(-50%, -50%);
 overflow: hidden;
}
.loading:after {
 content: '';
 display: block;
 width: 100%;
 height: 4px;
 background: #fff;
 animation: load 15s linear;
}
@-moz-keyframes load {
 0% {
 width: 0;
 }
 10% {
 width: 5%;
 }
 20% {
 width: 15%;
 }
 30% {
 width: 25%;
 }
 40% {
 width: 30%;
 }
 50% {
 width: 44%;
 }
 60% {
 width: 50%;
 }
 70% {
 width: 72%;
 }
 80% {
 width: 84%;
 }
 90% {
 width: 92%;
 }
 100% {
 width: 100%;
 }
}
@-webkit-keyframes load {
 0% {
 width: 0;
 }
 10% {
 width: 5%;
 }
 20% {
 width: 15%;
 }
 30% {
 width: 25%;
 }
 40% {
 width: 30%;
 }
 50% {
 width: 44%;
 }
 60% {
 width: 50%;
 }
 70% {
 width: 72%;
 }
 80% {
 width: 84%;
 }
 90% {
 width: 92%;
 }
 100% {
 width: 100%;
 }
}
@-o-keyframes load {
 0% {
 width: 0;
 }
 10% {
 width: 5%;
 }
 20% {
 width: 15%;
 }
 30% {
 width: 25%;
 }
 40% {
 width: 30%;
 }
 50% {
 width: 44%;
 }
 60% {
 width: 50%;
 }
 70% {
 width: 72%;
 }
 80% {
 width: 84%;
 }
 90% {
 width: 92%;
 }
 100% {
 width: 100%;
 }
}
@keyframes load {
 0% {
 width: 0;
 }
 10% {
 width: 5%;
 }
 20% {
 width: 15%;
 }
 30% {
 width: 25%;
 }
 40% {
 width: 30%;
 }
 50% {
 width: 44%;
 }
 60% {
 width: 50%;
 }
 70% {
 width: 72%;
 }
 80% {
 width: 84%;
 }
 90% {
 width: 92%;
 }
 100% {
 width: 100%;
 }
}
</style>
<section><div class="loading"></div></section>

