import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'src/app/models/edu/subject.model';
import User from 'src/app/models/user';
import { HttpRepoService } from 'src/app/repository/http/http/http_repo.service';
import { DiaryDataService } from 'src/app/services/base/diary-data.service';
import { SalesStorageService } from 'src/app/services/storage/sales/sales-storage.service';

@Component({
  selector: 'app-add-update-payment',
  templateUrl: './add-update-payment.component.html',
  styleUrls: ['./add-update-payment.component.css']
})
export class AddUpdatePaymentComponent implements OnInit {
  userForm:User={}
  @Output() currentView : EventEmitter<string> = new EventEmitter()
  @Input() id=''
  title='Pagamentos de Emolumentos'
  qtd=1
  client:string=''
  subjectForm:Subject={}
  monthsNames:any
  seletedMonthNames:any
  produts:any
  itemSales:any=[]
  itemTemp={
    name:'',
    price:0,
    month_id:'',
    qtd:0,
    tax:0,
    month:'',
    product_id:'',
    total:0,
    user_id:'',
    source_document_id:'',
    diary:0

  }
  finalizePay=false
  levelsNames:any
  courseNames:any
  dropdownSettings: IDropdownSettings |any
  source_document_id: string='';
  user_id: string='';
  lastSales: any;
  endDoc={
    totalReceived:0,
  remaining:0,
  date_pay:'',
  Docnumber:'',
  source_document_id:'',
  total:0,
  gross_total:0
  }
  preProduts: any;

  constructor(private http:HttpRepoService, private toastr:ToastrService,private storageSales:SalesStorageService) { }
  ngOnChanges(changes: SimpleChanges): void {
    // idUser.currentValue
  //console.log(changes)

  if(this.id!=''){
    this.getSubject()
  }

  }
  ngOnInit(): void {
   this.initializeSales()
  }
  initializeSales() {
    try{
      //chckeck exit sales in storage
      this.lastSales=this.storageSales.getSalesStorage()
      console.log(this.lastSales.doc)
       if(this.lastSales.doc )
       {
         this.endDoc.total=this.lastSales.totalPositivo
         this.endDoc.gross_total=this.lastSales.totalPositivo
         this.endDoc.source_document_id=this.lastSales.doc
         this.finalizePay=true
       }
       else{


       this.preaload()


      this.subjectForm.diary=parseInt(DiaryDataService.getDiary())
     // this.subjectForm.id=uuid()
   }
     //end check sales


    }
    catch(ex:any){
     this.preaload()
    }
  }
  getAllMonths(){
    this.http.All("/months").subscribe((res)=>{
      this.monthsNames=res.data

    },((error)=>{
      if(error.status==401){
        this.toastr.error("Não autorizado!")
      }
     else if(error.status==403){
        this.toastr.error(error.error)
      }
      else{
        this.toastr.error(error.message)

      }    console.log(error)
    }))
  }

  getCoursesList(){
    this.http.All("/courses").subscribe((res)=>{

      this.courseNames=res.data

    },((error)=>{
      if(error.status==401){
        this.toastr.error("Não autorizado!")
      }
     else if(error.status==403){
        this.toastr.error(error.error)
      }
      else{
        this.toastr.error(error.message)

      }    console.log(error)
    }))
  }



  getLevesList(){
    this.http.All("/levels").subscribe((res)=>{
      this.levelsNames=res.data
    },((error)=>{
      if(error.status==401){
        this.toastr.error("Não autorizado!")
      }
     else if(error.status==403){
        this.toastr.error(error.error)
      }
      else{
        this.toastr.error(error.message)

      }    console.log(error)
    }))
  }

onSelectCourse(items:any,op:number){

   try{
    switch(op){
      case 1:
        this.produts=this.preProduts.filter((p: { course_id: any; })=>p.course_id==items.id)
        break
      }

   }
   catch(ex:any)
   {

   }
    }
backToList(code:string){
    this.currentView.emit(code);


  try{
6
  }catch(ex){

  }
    //$('#'+ev).toggleClass('show')
   }
  save(){

    this.http.post(this.itemSales,"/payments").subscribe((res)=>{

  //console.log(res)
  if(res.data){
    this.toastr.success("Salvo com sucesso!")
    this.id=''
    this.subjectForm={}
    this.finalizePay=true
    this.storageSales.saveSales(res.data)
    this.initializeSales()
    this.finalizePay=true
  //  this.backToList('classeslist')
  } else{
    this.toastr.error('Não guardado!')

  }
    },((error)=>{
      if(error.status==401){
        this.toastr.error("Não autorizado!")
      }
     else if(error.status==403){
        this.toastr.error(error.message)
      }
      else{
        this.toastr.error(error.message)

      }    console.log(error)
    }))
  }

  finalizeDoc(){

    this.http.post(this.endDoc,"/payments/finalize").subscribe((res)=>{

  //console.log(res)
  if(res.data){
    this.toastr.success("Salvo com sucesso!")
    this.id=''
    this.subjectForm={}
    this.finalizePay=true
window.localStorage.clear()
this.initializeSales()
   // this.backToList('classeslist')
  } else{
    this.toastr.error('Não guardado!')

  }
    },((error)=>{
      if(error.status==401){
        this.toastr.error("Não autorizado!")
      }
     else if(error.status==403){
        this.toastr.error(error.message)
      }
      else{
        this.toastr.error(error.message)

      }    console.log(error)
    }))
  }
getUUID(){
  this.http.All("/uuids").subscribe((res)=>{
    this.subjectForm.id=res.data
    this.source_document_id=res.data

  },((error)=>{
    if(error.status==401){
      this.toastr.error("Não autorizado!")
    }
   else if(error.status==403){
      this.toastr.error(error.error)
    }
    else{
      this.toastr.error(error.message)

    }    console.log(error)
  }))
}
getMyInstitute(){
  this.http.All("/institutes/my-institute").subscribe((res)=>{
   //institute_id=res.data.id

  },((error)=>{
    if(error.status==401){
      this.toastr.error("Não autorizado!")
    }
   else if(error.status==403){
      this.toastr.error(error.error)
    }
    else{
      this.toastr.error(error.message)

    }    console.log(error)
  }))
}
getSubject(){
  this.http.All("/subjects/"+this.id).subscribe((res)=>{
    if(res.data){
      this.subjectForm=res.data
      this.toastr.success(res.message)
    }

  },((error)=>{
    if(error.status==401){
      this.toastr.error("Não autorizado!")
    }
   else if(error.status==403){
      this.toastr.error(error.error)
    }
    else{
      this.toastr.error(error.message)

    }    console.log(error)
  }))
}
onSelect(ev:any,id:number){
  this.itemTemp.month_id=ev.id
  this.itemTemp.month=ev.name
  this.itemTemp.qtd=1
  this.itemTemp.user_id=this.user_id
  this.itemTemp.source_document_id=this.source_document_id

}
monthRequired(op:string){
  let isMonth=false
if(op=='DC01 - SERVICO FIXO'){
  isMonth=true
}
return isMonth
}
addItem(name:string,product_id:string,price:number){
  this.itemTemp.name=name
  if( this.source_document_id==''){
    this.toastr.error("Sem documento!")
  }else{
  this.itemTemp.product_id=product_id
  this.itemTemp.price=price
  this.itemSales.push(this.itemTemp)
  this.itemTemp.total=this.itemTemp.price*this.itemTemp.qtd
  this.itemTemp.user_id=this.user_id

  this.itemTemp.source_document_id=this.source_document_id
  this.itemTemp.diary=parseInt(DiaryDataService.getDiary())
  this.itemTemp={ name:'',
  price:0,
  month_id:'',
  qtd:0,
  tax:0,
  month:'',
  product_id:'',
  total:0,
  user_id:'',
  source_document_id:'',
  diary:0
}
this.qtd=0
this.toastr.success("Produto adicionada!","ADD SALES")
}
}
getValueForm(ev:any){

this.itemTemp.qtd=parseInt(ev.target.value)
}
async getAllProducts(){
  this.http.All("/products/"+this.id).subscribe((res)=>{
    const data=res.data

    this.produts=data
    this.preProduts=data



  },((error)=>{
    if(error.status==401){
      this.toastr.error("Não autorizado!")
    }
   else if(error.status==403){
      this.toastr.error(error.error)
    }
    else{
      this.toastr.error(error.message)

    }    console.log(error)
  }))
}


async getClass(){
  this.http.All("/students/"+this.id).subscribe((res)=>{
    const data=res.data

    this.userForm=data
  this.client= data.id
  this.user_id=data.user_id
  this.produts=this.preProduts.filter((p: { course_id: any; })=>p.course_id==data.course_id)
 console.log(data)

  },((error)=>{
    if(error.status==401){
      this.toastr.error("Não autorizado!")
    }
   else if(error.status==403){
      this.toastr.error(error.error)
    }
    else{
      this.toastr.error(error.message)

    }    console.log(error)
  }))
}

search(){
  this.id=this.userForm.identity_card || ''
  this.getClass()
}

preaload(){

  this.dropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };

  this.getLevesList()
  this.getCoursesList()
  this.getAllProducts()
  this.getAllMonths()

  if(this.id==''){
    this.getUUID()
  }else{
    this.getSubject()
  }

}
}
