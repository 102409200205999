

    <!-- begin container-fluid -->
    <div class="container-fluid">
      <!-- begin row -->
      <div class="row">
          <div class="col-md-12 m-b-30">
              <!-- begin page title -->
              <div class="d-block d-lg-flex flex-nowrap align-items-center">
                  <div class="page-title mr-4 pr-4 ">
                      <h4> {{title}}</h4>
                      <p></p>

                  </div>
                  <div class="ml-auto d-flex align-items-center secondary-menu text-center">
                    <a data-toggle="modal"  (click)="save()" class="tooltip-wrapper" title="Salvar">

                      <i class="fa fa-save btn btn-icon text-primary"></i> </a>
                    <a data-toggle="modal"  class="tooltip-wrapper" title="Adicionar">

                          <i class="fa fa-refresh btn btn-icon text-primary" (click)="refress()"></i> </a>



                      <a class="tooltip-wrapper" data-toggle="tooltip" data-placement="top" title="" data-original-title="Listar">
                          <i class="fa fa-list btn btn-icon text-primary" (click)="backToList('studentslist')"></i> </a>
                  </div>
              </div>
              <!-- end page title -->
          </div>
      </div>




      <div class="row formavlidation-wrapper  ">
        <div class="col-12 col-lg-12">
          <div class="accordion" id="accordionsimplefill">
            <div class="mb-2 acd-group">
              <div class="card-header rounded-0 bg-according">
                <h6 class="mb-0">
                  <a href="#collapse2Academicos" class="btn-block text-white text-left acd-heading collapsed"
                    data-toggle="collapse"> Dados Académicos</a>
                </h6>
              </div>
              <div id="collapse2Academicos" class="collapse" data-parent="#accordionsimplefill">
                <div class="row">
                  <div class="col-12 col-lg-12">

                    <div class="card card-statistics">
                      <div class="card-body">

                        <fieldset>


      <div class="row formavlidation-wrapper  ">

        <div class="col-md-12">
            <div class="card card-statistics">
                <div class="page-title mr-4 pr-4 " style="margin:20px 0px 0px 20px ">
                    <h6 class="text-danger"> Preencha os dados académicos</h6>
                </div>
                <div class="card-body">


                    <div class="form-row">

                      <div class="col-md-3 col-sm-12">
                        <div class="form-group">
                            <label class="control-label">Disciplina*</label>
                            <ng-multiselect-dropdown [placeholder]="' -- seleciona uma opção -- '" [settings]="dropdownSettings"
                            [data]="subjectsName"
                            (onSelect)="onSelect($event,1)"
                             name="selectedSubject" [(ngModel)]="selectedSubject">

                           </ng-multiselect-dropdown>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-12">
                      <div class="form-group">
                          <label class="control-label">Frequencia*</label>
                          <ng-multiselect-dropdown [placeholder]="' -- seleciona uma opção -- '" [settings]="dropdownSettings"
                          [data]="levelsNames"
                          (onSelect)="onSelect($event,2)"
                           name="selectedSubject" [(ngModel)]="seletedLevelsNames">

                         </ng-multiselect-dropdown>
                      </div>
                  </div>
                  <div class="col-md-3 col-sm-12">
                    <div class="form-group">
                        <label class="control-label">Ciclo*</label>
                        <ng-multiselect-dropdown [placeholder]="' -- seleciona uma opção -- '" [settings]="dropdownSettings"
                        [data]="typeNames"
                        (onSelect)="onSelect($event,3)"
                         name="selectedSubject" [(ngModel)]="seletedTypeNames">

                       </ng-multiselect-dropdown>
                    </div>
                </div>
                    <div class="col-md-3 col-sm-12">
                      <div class="form-group">
                          <label class="control-label">Curso *</label>
                          <ng-multiselect-dropdown [placeholder]="' -- seleciona uma opção -- '" [settings]="dropdownSettings"
                          [data]="courseNames"
                          (onSelect)="onSelect($event,4)"
                           name="seletedCourseNames" [(ngModel)]="seletedCourseNames">

                         </ng-multiselect-dropdown>
                      </div>
                  </div>

                <div class="col-md-2 col-sm-12">
                  <div class="form-group">
                      <label class="control-label">Código*</label>
                      <input required="required"  class="form-control" type="text" [(ngModel)]="formSubject.code" name="userForm.end_year" required="" placeholder="Código ">

                  </div>
              </div>
                </div>

            </div>
        </div>
    </div>



</div>

                        </fieldset>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>


</div>



