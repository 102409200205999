import { Injectable } from '@angular/core';
const key_sale='SALES'
@Injectable({
  providedIn: 'root'
})
export class SalesStorageService {

  constructor() { }
  saveSales(sales:any[]){
    window.localStorage.setItem(key_sale,JSON.stringify(sales))
  }
  getSalesStorage(){
   try{
    const sales=window.localStorage.getItem(key_sale)
    if(sales){
      return JSON.parse(sales)
    }

   }
   catch(ex:any){


   }
  }
}
