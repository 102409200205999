<!--Navbar -->
<nav class="mb-1 navbar navbar-expand-lg navbar-dark color-white" style="height: 40px; background: none;">
  <a class="navbar-brand" href="#"><img src="assets/logo.png" width="40" alt=""></a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent-4"
    aria-controls="navbarSupportedContent-4" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent-4">
    <ul class="navbar-nav ml-auto">
      <li class="nav-item active">
        <a class="nav-link" href="#">
          <i class="fa fa-facebook"></i>
          <span class="sr-only">(current)</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#">
          <i class="fa fa-instagram"></i> </a>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link" id="navbarDropdownMenuLink-4" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <i class="fa fa-envelope bg-dark" style="color: yellow; border-radius: 8px;">1</i>  </a>
        <div class="dropdown-menu dropdown-menu-right dropdown-info" aria-labelledby="navbarDropdownMenuLink-4">
          <a class="dropdown-item" href="#"></a>
          <a class="dropdown-item" href="#">Log out</a>
        </div>
      </li>

      <li class="nav-item dropdown">
        <a class="nav-link" id="navbarDropdownMenuLink-4" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <i class="fa fa-user"></i>  </a>
        <div class="dropdown-menu dropdown-menu-right dropdown-info" aria-labelledby="navbarDropdownMenuLink-4">
          <a class="dropdown-item fa fa-user btn btn-icon text-primary" href="#"> Perfil</a>
          <a class="dropdown-item fa fa-power-off btn btn-icon text-primary" (click)="logoff()"> Sair</a>

          <a class="dropdown-item fa fa-refresh btn btn-icon text-primary" (click)="reload()"> Reflesh</a>
        </div>
      </li>
    </ul>
  </div>
</nav>
<!--/.Navbar -->
