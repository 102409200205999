<div class="container-fluid">

<app-header-dashoar-primary (outputFromHeaderCurrentPath)="receiveChildData($event)"></app-header-dashoar-primary>




  <div class="resizable-windows">
    <div id="resizable-box" class="ui-widget-content resizable">
        <div class="box-header">
            <div class="control-buttons">
                <div class="buttons">
                    <img src="./assets/layout-mac/images/finder/red.png" alt="" id="red-cross">
                    <img src="./assets/layout-mac/images/finder/yellow.png" alt="">
                    <img src="./assets/layout-mac/images/finder/green.png" alt="">
                </div>
                <div class="folder-name">
                    <img src="./assets/layout-mac/images/folder-icon.png" alt=""><span> {{currentApp}}</span>
                </div>
            </div>
            <div class="control-buttons2">
                <div class="buttons-left">
                    <button><img src="./assets/layout-mac/images/finder/left-arrow.svg" alt=""></button>
                    <button><img src="./assets/layout-mac/images/finder/right-arrow.svg" alt=""></button>
                </div>
                <div class="buttons-right">
                    <div class="menu-icon-buttons">
                        <div class="sort-button">
                            <button><img src="./assets/layout-mac/images/finder/menu1.svg" alt=""></button>
                            <button><img src="./assets/layout-mac/images/finder/menu2.svg" alt=""></button>
                            <button><img src="./assets/layout-mac/images/finder/menu3.svg" alt=""></button>
                            <button><img src="./assets/layout-mac/images/finder/menu4.svg" alt=""></button>
                        </div>
                        <div class="setting-button">
                            <button><img src="./assets/layout-mac/images/finder/menu5.svg" alt=""><span><img
                                        src="./assets/layout-mac/images/finder/expand.svg" alt=""></span></button>
                            <button><img src="./assets/layout-mac/images/finder/settings.svg" alt=""><span><img
                                        src="./assets/layout-mac/images/finder/expand.svg" alt=""></span></button>
                            <button><img src="./assets/layout-mac/images/finder/export.svg" alt=""></button>
                            <button><img src="./assets/layout-mac/images/finder/tag.png" alt=""></button>
                        </div>
                    </div>
                    <div class="search-menu-bar">
                        <input type="text" placeholder="Search">
                    </div>

                </div>
            </div>
        </div>
        <div class="box-body">
            <div class="box-sidebar ">
                <div class="sidebar-background">
                    <div class="sidebar-items">
                        <div class="item-category">
                            Favourites
                        </div>
                        <app-app-container-open [id_departament]="departament_id" *ngIf="departament_id!=''" (selectedApp)="receivedApp($event)"></app-app-container-open>
                    </div>
                </div>
            </div>
            <div class="box-main" id="main-box">
                <div  class="app-layout hide">
                  <div *ngIf="currentCompnent">
                    <ng-container *ngComponentOutlet="currentCompnent"></ng-container>

                  </div>

                <!--  <app-users class="scr"></app-users>-->

                  </div>
                <div id="sidebar-recents" class="app-layout hide" style="margin-bottom: 0;position: fixed;">
                    <div class="align-center"><img src="./assets/layout-mac/images/apps/recents/camera.svg" alt="">Camera</div>
                    <div class="align-center"><img src="./assets/layout-mac/images/apps/recents/notes.png" alt="">Notes</div>
                    <div class="align-center"><img src="./assets/layout-mac/images/apps/recents/office.svg" alt="">Office</div>
                    <div class="align-center"><img src="./assets/layout-mac/images/apps/recents/settings.png" alt="">Settings</div>
                    <div class="align-center"><img src="./assets/layout-mac/images/apps/recents/window.png" alt="">Window</div>
                </div>

                <div id="sidebar-applications" class="app-layout hide">
                    <div class="align-center"><img src="./assets/layout-mac/images/apps/ae.png" alt="">Adobe After Effects CC 2019
                    </div>
                    <div class="align-center"><img src="./assets/layout-mac/images/apps/air.png" alt="">AirDrop</div>
                    <div class="align-center"><img src="./assets/layout-mac/images/apps/backup.png" alt="">Time Machine</div>
                    <div class="align-center"><img src="./assets/layout-mac/images/apps/bitcoin.png" alt="">Bitcoin</div>
                    <div class="align-center"><img src="./assets/layout-mac/images/apps/book.png" alt="">Books</div>
                    <div class="align-center"><img src="./assets/layout-mac/images/apps/calculator.png" alt="">Calculator</div>
                    <div class="align-center"><img src="./assets/layout-mac/images/apps/calendar.png" alt="">Calendar</div>
                    <div class="align-center"><img src="./assets/layout-mac/images/apps/chrome.png" alt="">Google Chrome</div>
                    <div class="align-center"><img src="./assets/layout-mac/images/apps/mail.png" alt="">Mail</div>
                    <div class="align-center"><img src="./assets/layout-mac/images/apps/messages.png" alt="">Messages</div>
                    <div class="align-center"><img src="./assets/layout-mac/images/apps/music.png" alt="">Music</div>
                    <div class="align-center"><img src="./assets/layout-mac/images/apps/onedrive.png" alt="">Google One Drive</div>
                    <div class="align-center"><img src="./assets/layout-mac/images/apps/pics.png" alt="">Pictures</div>
                    <div class="align-center"><img src="./assets/layout-mac/images/apps/ps.png" alt="">Adobe Photoshop CC 2019</div>
                    <div class="align-center"><img src="./assets/layout-mac/images/apps/safari.png" alt="">Safari</div>
                    <div class="align-center"><img src="./assets/layout-mac/images/apps/spotify.png" alt="">Spotify</div>
                    <div class="align-center"><img src="./assets/layout-mac/images/apps/stockmarket.png" alt="">Stockmarket</div>
                    <div class="align-center"><img src="./assets/layout-mac/images/apps/stocks.png" alt="">Stocks</div>
                    <div class="align-center"><img src="./assets/layout-mac/images/apps/textedit.png" alt="">TextEdit</div>
                    <div class="align-center"><img src="./assets/layout-mac/images/apps/tv.png" alt="">Apple Tv</div>
                    <div class="align-center"><img src="./assets/layout-mac/images/apps/twitter.png" alt="">Twitter</div>
                    <div class="align-center"><img src="./assets/layout-mac/images/apps/xcode.png" alt="">Xcode</div>
                </div>
                <div id="sidebar-google-drive" class="app-layout hide">
                    <div class="align-center"><img src="./assets/layout-mac/images/folder-icon.png" alt="">My Documents</div>
                </div>
                <div id="sidebar-desktop" class="app-layout hide">
                    <div class="align-center"><img src="./assets/layout-mac/images/folder-icon.png" alt="">Web Development</div>
                    <div class="align-center"><img src="./assets/layout-mac/images/folder-icon.png" alt="">My Projects</div>
                </div>
                <div id="sidebar-documents" class="app-layout hide">
                    <div class="align-center"><img src="./assets/layout-mac/images/folder-icon.png" alt="">My Local Documents</div>
                </div>
                <div id="sidebar-pictures" class="app-layout hide">
                    <div class="align-center"><img src="./assets/layout-mac/images/apps/photos.png" alt="">Photos</div>
                </div>
                <div id="sidebar-your-macbook-pro" class="app-layout hide">
                    <div class="align-center"><img src="./assets/layout-mac/images/disk.png" alt="">Macintosh HD</div>
                    <div class="align-center"><img src="./assets/layout-mac/./assets/layout-mac/images/globe.png" alt="">Network</div>
                </div>
            </div>
        </div>
        <div class="box-footer"></div>
    </div>
</div>
<app-footer-primary (deparatamenID)="receivedID($event)"></app-footer-primary>


  </div>

