
        <app-modal-container></app-modal-container>
        <!-- begin container-fluid -->
        <div class="container-fluid">
          <!-- begin row -->
          <div class="row">
            <div class="col-md-12 m-b-30">
              <!-- begin page title -->
              <div class="d-block d-lg-flex flex-nowrap align-items-center">
                <div class="page-title mr-4 pr-4 ">
                  <h4 class="page-title"> {{currentCompnent}} </h4>

                </div>

                <div class="ml-auto d-flex align-items-center secondary-menu text-center" id="modal_shadow_bottom">
                  <a  data-toggle="modal" data-target="" (click)="addUser('newupdateusers')"
                    class="tooltip-wrapper" title="Adicionar">
                    <i class="fa fa-plus-circle btn btn-icon text-primary"></i> </a>



                  <a  class="tooltip-wrapper" data-toggle="tooltip" data-placement="top" title=""
                  data-original-title="Exportar Pdf" (click)="addUser('user')">
                  <i class="fa fa-file-pdf-o btn btn-icon text-cyan"></i> </a>
                <a  class="tooltip-wrapper" data-toggle="tooltip" data-placement="top" title=""
                  data-original-title="Exportar Excel">
                  <i class="fa fa-file-excel-o btn btn-icon text-cyan"></i> </a>


                  <a routerLink="/registoprimariomatricula"   class="tooltip-wrapper" data-toggle="tooltip" data-placement="top" title="Registo Primário"
                  data-original-title="Registo Primário">
                  <i class="fa fa-file-excel-o btn btn-icon text-cyan"></i> </a>
                  <a routerLink="/matrilasemestrais"  class="tooltip-wrapper" data-toggle="tooltip" data-placement="top" title="Matrículas Semestrais"
                  data-original-title="Matrículas Semestrais">
                  <i class="fa fa-file-excel-o btn btn-icon text-cyan"></i> </a>
                </div>
              </div>
              <!-- end page title -->
            </div>
          </div>
            <hr>
<app-filetr-according></app-filetr-according>
          <div class="row">
            <div class="col-12 col-lg-12">
              <div class="card card-statistics">

                <div class="card-body">

                  <div class="  table-responsive">
                        <div class="row float-right p-1">
                          <div class="col-md-12">
                            <input type="text" [(ngModel)]="filternName" class="form-control" name="filter" (keyup)="filter($event)">
                          </div>
                        </div>
                    <table
                      class="table table-bordered  table-striped table-sm">



                      <thead class="text-light bg-info">
                        <tr>
                          <th class="header" scope="col" width="10%">Imagem</th>
                          <th class="header" scope="col" width="10%">Utilizador</th>

                          <th class="header" scope="col" width="40%">Identificação</th>
                          <th class="header" scope="col" width="40%">Nome</th>
                          <th class="header" scope="col" width="40%">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let matricula of users | tableFilter: filternName">
                        <td><img src="{{matricula?.EduEstudante?.picture}}" alt="" width="100" ></td>

                          <td>{{matricula?.username}}</td>

                          <td>{{matricula?.identity_card}}</td>
                          <td>{{matricula?.name}}</td>
                          <td><i class="fa fa-eye" (click)="sendId(matricula?.id,'user')"></i> | <i class="fa fa-edit" (click)="sendId(matricula?.id,'newupdateusers')"></i></td>
                         </tr>
                      </tbody>
                    </table>
                      <strong>     Total:{{totalRows}} | Page:  {{lastPage}}</strong>
                    <ul class="pagination">

                      <li class="page-item"><a class="page-link" href="#" (click)="previousPage()">Previous</a></li>
                    <!--  <li class="page-item" *ngFor="let pag of [].constructor(totalPages);let i=index"><a class="page-link" href="#" (click)="SetnextPageOrpreviousPage(i)">{{i}}</a></li>
                      -->
                      <li class="page-item"><a class="page-link" href="#" (click)="nextPageOrpreviousPage()">Next</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end row -->
        </div>
        <!-- end container-fluid -->
