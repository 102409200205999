import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-container',
  templateUrl: './modal-container.component.html',
  styleUrls: ['./modal-container.component.css']
})
export class ModalContainerComponent implements OnInit {
  modal_dialogo_full= 'modal-dialog-full'
  modal_content_full= 'modal-content-full'
  modal_body_full= ' modal-body-full'
  constructor() { }

  ngOnInit(): void {
  }

  minimize(){

    if(  this.modal_dialogo_full== 'modal-dialog-full'&&this.modal_content_full== 'modal-content-full'&& this.modal_body_full==' modal-body-full'){

      this.modal_dialogo_full= ''
      this.modal_content_full= ''
      this.modal_body_full= ''
    }
    else{
   this.modal_dialogo_full= 'modal-dialog-full'
   this.modal_content_full= 'modal-content-full'
   this.modal_body_full= ' modal-body-full'}
  }
}
