import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpRepoService } from 'src/app/repository/http/http/http_repo.service';

@Component({
  selector: 'app-footer-win11',
  templateUrl: './footer-win11.component.html',
  styleUrls: ['./footer-win11.component.css']
})
export class FooterWin11Component implements OnInit {

//apps=myApps
apps:any
@Output() deparatamenID: EventEmitter<number>=new EventEmitter()
constructor(private http:HttpRepoService,private toastr: ToastrService) {
  this.http.All("/departaments/user").subscribe((res)=>{
    this.apps=res.data
console.log(res)
  },((error)=>{
    if(error.status==401){
      this.toastr.error("Não autorizado!")
    }
   else if(error.status==403){
      this.toastr.error(error.error)
    }
    else{
      this.toastr.error(error.message)

    }    console.log(error)
  }))
 }

  ngOnInit(): void {
    console.log(this.apps)
  }
sendID(id:number){
this.deparatamenID.emit(id)
}}
