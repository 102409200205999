<div class="item-selected">
  <img src="./assets/layout-mac/images/menu/airdrop.png" alt=""><a href="#">AirDrop</a>
</div>
<div class="item-selected">
  <img src="./assets/layout-mac/images/menu/recents.png" alt=""><a href="#">Recents</a>
</div>
<div class="item-selected" name="applications">
  <img src="./assets/layout-mac/images/menu/apps.png" alt=""><a href="#">Applications</a>
</div>
<div class="item-selected">
  <img src="./assets/layout-mac/images/menu/folder.png" alt=""><a href="#">Google Drive</a>
</div>
<div class="item-selected">
  <img src="./assets/layout-mac/images/menu/desktop.png" alt=""><a href="#">Desktop</a>
</div>
<div class="item-selected">
  <img src="./assets/layout-mac/images/menu/documents.png" alt=""><a href="#">Documents</a>
</div>
<div class="item-selected">
  <img src="./assets/layout-mac/images/menu/download.png" alt=""><a href="#">Downloads</a>
</div>
<div class="item-selected">
  <img src="./assets/layout-mac/images/menu/pictures.png" alt=""><a href="#">Pictures</a>
</div>
<div class="item-category">
  iCloud
</div>
<div class="item-selected">
  <img src="./assets/layout-mac/images/menu/icloud.png" alt=""><a href="#">iCloud Drive</a>
</div>
<div class="item-category">
  Locations
</div>
<div class="item-selected">
  <img src="./assets/layout-mac/images/menu/laptop.png" alt=""><a href="#">Your MacBook Pro</a>
</div>
<div class="item-category">
  Tags
</div>
