import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-container-apps',
  templateUrl: './app-container-apps.component.html',
  styleUrls: ['./app-container-apps.component.css']
})
export class AppContainerAppsComponent implements OnInit {
@Input() departamentID:number=0
  constructor() { }

  ngOnInit(): void {
  }

}
