
<section *ngIf="applicatons">


<div class="item-selected"  *ngFor="let __app of applicatons" >
  <img src="./assets/layout-mac/images/menu/folder.png" alt="" ><a href="#" (click)="sendApplication(__app.code)">{{__app.name}}</a>
</div>

<div class="item-category">
  Núvem
</div>
<div class="item-selected">
  <img src="./assets/layout-mac/images/menu/icloud.png" alt=""><a href="#">Backup na Núvem</a>
</div>
<div class="item-category">
  Armazenamento Local
</div>
<div class="item-selected">
  <img src="./assets/layout-mac/images/menu/laptop.png" alt=""><a href="#">Backup no PC</a>
</div>




</section>
