import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'src/app/models/edu/subject.model';

import { HttpRepoService } from 'src/app/repository/http/http/http_repo.service';
import { DiaryDataService } from 'src/app/services/base/diary-data.service';
import { periodsData } from 'src/app/static-data/periods.data';

@Component({
  selector: 'app-subjects-add-update',
  templateUrl: './subjects-add-update.component.html',
  styleUrls: ['./subjects-add-update.component.css']
})
export class SubjectsAddUpdateComponent implements OnInit {

  @Output() currentView : EventEmitter<string> = new EventEmitter()
  @Input() id=''
  title='Novo'

  subjectForm:Subject={}
  constructor(private http:HttpRepoService, private toastr:ToastrService) { }
  ngOnChanges(changes: SimpleChanges): void {
    // idUser.currentValue
  //console.log(changes)

  if(this.id!=''){
    this.getSubject()
  }

  }
  ngOnInit(): void {
      //   this.getMyInstitute()
    if(this.id==''){
      this.getUUID()
    }else{
      this.getSubject()
    }

    this.subjectForm.diary=parseInt(DiaryDataService.getDiary())
   // this.subjectForm.id=uuid()

  }

backToList(code:string){
    this.currentView.emit(code);


  try{
6
  }catch(ex){

  }
    //$('#'+ev).toggleClass('show')
   }
  save(){

    this.http.post(this.subjectForm,"/subjects").subscribe((res)=>{

  //console.log(res)
  if(res.data){
    this.toastr.success("Salvo com sucesso!")
    this.id=''
    this.subjectForm={}
    this.backToList('classeslist')
  } else{
    this.toastr.error('Não guardado!')

  }
    },((error)=>{
      if(error.status==401){
        this.toastr.error("Não autorizado!")
      }
     else if(error.status==403){
        this.toastr.error(error.message)
      }
      else{
        this.toastr.error(error.message)

      }    console.log(error)
    }))
  }
getUUID(){
  this.http.All("/uuids").subscribe((res)=>{
    this.subjectForm.id=res.data

  },((error)=>{
    if(error.status==401){
      this.toastr.error("Não autorizado!")
    }
   else if(error.status==403){
      this.toastr.error(error.error)
    }
    else{
      this.toastr.error(error.message)

    }    console.log(error)
  }))
}
getMyInstitute(){
  this.http.All("/institutes/my-institute").subscribe((res)=>{
   // this.subjectForm.institute_id=res.data.id

  },((error)=>{
    if(error.status==401){
      this.toastr.error("Não autorizado!")
    }
   else if(error.status==403){
      this.toastr.error(error.error)
    }
    else{
      this.toastr.error(error.message)

    }    console.log(error)
  }))
}
getSubject(){
  this.http.All("/subjects/"+this.id).subscribe((res)=>{
    if(res.data){
      this.subjectForm=res.data
      this.toastr.success(res.message)
    }

  },((error)=>{
    if(error.status==401){
      this.toastr.error("Não autorizado!")
    }
   else if(error.status==403){
      this.toastr.error(error.error)
    }
    else{
      this.toastr.error(error.message)

    }    console.log(error)
  }))
}

}
