
<style>
@media screen and (max-width: 600px) {
  body {
    width: 100%;
  }
}

.footer-app{
  position: fixed;
left: 0;
bottom: 0;
width: 100%;
height: 70px;
background: rgb(2,0,36);
background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(4,8,40,1) 69%, rgba(9,9,121,1) 100%);  color: white;
text-align: center;

-webkit-box-shadow: 2px 0px 99px 0px rgba(54,85,199,1);
-moz-box-shadow: 2px 0px 99px 0px rgba(54,85,199,1);
box-shadow: 2px 0px 99px 0px rgba(54,85,199,1);
border: none;
}
.search-app{
  border-radius: 20px;
  border: none;
  background: rgb(18, 18, 63);
  text-align: center;
  color: aliceblue;
}
input{
border:#ddd solid 1px;
float:left;
padding:5px 20px 5px 5px
}

/* formatação do elemento */
#lupa{
cursor:pointer
}

/* formatação do conteúdo  */
#lupa:after{
font-family:FontAwesome;
font-size:11px;
content:"\f002";
color: aliceblue;

}
</style><!--<app-app-modal-win11></app-app-modal-win11>-->




<div class="modal fade" id="modal-app">
  <div class="modal-dialog modal-lg modal-dialog-full ">
    <div class="modal-content modal-content-full">
      <div class="modal-header">
        <h4 class="modal-title">{{currentApp}}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body modal-body-full">
        <div class="container-fluid">
          <div class="row">

            <div class="col-md-2">
              <app-app-container-open-win11 [id_departament]="departament_id" *ngIf="departament_id!=''" (selectedApp)="receivedApp($event)"></app-app-container-open-win11>


            </div>


            <div class="col-md-10">
              <div *ngIf="currentCompnent">
                <ng-container *ngComponentOutlet="currentCompnent"></ng-container>

              </div>
            </div>
          </div>



      </div>
      <!--End container-->
      </div>
      <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>
<!-- /.modal -->




<div class="modal fade" id="modal-app2">
  <div class="modal-dialog modal-lg modal-dialog-full ">
    <div class="modal-content modal-content-full">
      <div class="modal-header">
        <h4 class="modal-title">{{currentApp}}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body modal-body-full">
        <div class="container-fluid">
          <div class="row">

            <div class="col-md-1" >
              <app-app-container-open-win11  [id_departament]="departament_id" *ngIf="departament_id!=''" (selectedApp)="receivedApp($event)"></app-app-container-open-win11>


            </div>


            <div class="col-md-10">
              <div *ngIf="currentCompnent">
                <ng-container *ngComponentOutlet="currentCompnent"></ng-container>

              </div>
            </div>
          </div>



      </div>
      <!--End container-->
      </div>
      <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-default" ><i class="fa fa-user"></i><strong> Rodrino Kupessa</strong></button>
        <button type="button" class="btn btn-danger" ><i class="fa fa-power-off" (click)="off()"></i> </button>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>
<!-- /.modal -->




<footer class="bg-dark text-white py-3 footer-app">
  <div class="container">
     <app-footer-win11 (deparatamenID)="receivedID($event)"></app-footer-win11>

  </div>
</footer>
