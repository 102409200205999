import { Component, ComponentFactoryResolver, ComponentRef, ElementRef, EventEmitter, Injector, NgModuleFactory, NgModuleRef, OnInit, Output, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

import { SecurityDataService } from 'src/app/services/security/security-data.service';
import { repoApps } from 'src/app/services/shared/apps/core/apps';
import { AppContainerOpenComponent } from '../app-container-open/app-container-open.component';

@Component({
  selector: 'app-main-dashboard-primary',
  templateUrl: './main-dashboard-primary.component.html',
  styleUrls: ['./main-dashboard-primary.component.scss']
})
export class MainDashboardPrimaryComponent implements OnInit {
  currentApp=''
  departament_id=''


 col_10='col-md-12'
 col_2='col-md-0'
  modal_dialogo_full= 'modal-dialog-full'
  modal_content_full= 'modal-content-full'
  modal_body_full= ' modal-body-full'
  matriculas:any
  currentCompnent:any
  ngComponentOutletNgModuleFactory!: NgModuleFactory<any>
  private _componentRef: ComponentRef<any>|null = null;
  private _moduleRef: NgModuleRef<any>|null = null;
  ngComponentOutletInjector!: Injector;
  constructor(private _viewContainerRef: ViewContainerRef,private elementRef: ElementRef,private toastr: ToastrService, private __security:SecurityDataService) {
    this.__security.encrypt('ola')

   }
   ngOnChanges(changes: SimpleChanges) {
    this._viewContainerRef.clear();
    this._componentRef = null;

    if (this.currentCompnent) {
      const elInjector = this.ngComponentOutletInjector || this._viewContainerRef.parentInjector;

      if (changes['ngComponentOutletNgModuleFactory']) {
        if (this._moduleRef) this._moduleRef.destroy();

        if (this.ngComponentOutletNgModuleFactory) {
          const parentModule = elInjector.get(NgModuleRef);
          this._moduleRef = this.ngComponentOutletNgModuleFactory.create(parentModule.injector);
        } else {
          this._moduleRef = null;
        }
      }

      const componentFactoryResolver = this._moduleRef ? this._moduleRef.componentFactoryResolver :
                                                         elInjector.get(ComponentFactoryResolver);

      const componentFactory =
          componentFactoryResolver.resolveComponentFactory(this.currentCompnent);

      this._componentRef = this._viewContainerRef.createComponent(
          componentFactory, this._viewContainerRef.length, elInjector,
          this.currentCompnent);
    }
  }

  ngOnDestroy() {
    if (this._moduleRef) this._moduleRef.destroy();
  }
  ngOnInit(): void {
this.loadJS("./assets/layout-mac/lib/jquery-3.5.1.min.js")
this.loadJS("./assets/layout-mac/lib/jquery.resizeOnApproach.1.0.min.js")
this.loadJS("./assets/layout-mac/lib/jquery-ui.js")
this.loadJS("./assets/layout-mac/index.js")


  }
  loadJS(p:string){
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = p
    this.elementRef.nativeElement.appendChild(s);
  }
  sideBar(){
    if(this.col_10=='col-md-12' && this.col_2=='col-md-0'){

      this.col_10='col-md-10'
      this.col_2='col-md-2'
    }else{
      this.col_10='col-md-12'
      this.col_2='col-md-0'
    }
  }
  receiveChildData(data:any){

    this.currentCompnent=data
    console.log(data);
    }
    receiveCol_2(data:any){
      this.col_2=data
      console.log('col2:'+data);
      }
      receiveCol_10(data:any){
        this.col_10=data
        console.log('col2:'+data);
        }
        receivedID(ev:any){

          this.departament_id=ev
          if(ev!=''){
            this.currentCompnent=null

          }
           // alert(ev)
        }
        receivedApp(ev:any){
          const app= repoApps.find(a=>a.component===ev)?.name
          if( app){
            this.currentApp=app
        this.currentCompnent=ev
      }else{
        console.log('no app')
      }
        }
}
