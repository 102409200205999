import { Component, OnInit, Output ,EventEmitter, Input, SimpleChanges,OnChanges} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpRepoService } from 'src/app/repository/http/http/http_repo.service';
import { repoApps } from 'src/app/services/shared/apps/core/apps';

@Component({
  selector: 'app-app-container-open',
  templateUrl: './app-container-open.component.html',
  styleUrls: ['./app-container-open.component.css']
})
export class AppContainerOpenComponent implements OnInit {
@Output() selectedApp:EventEmitter<any>=new EventEmitter()
@Input() id_departament=''
  constructor(private http:HttpRepoService,private toastr: ToastrService) { }

 applicatons:any
  ngOnInit(): void {
   // this.app=repoApps[1].component



  }
  ngOnChanges(changes: SimpleChanges): void {
    // idUser.currentValue
 // console.log(changes)
  this.applicatons=[]
  this.sendApplication(repoApps[6].code)
  this.getRoles()
  //this.applicatons= repoApps.filter(a=>a.type===2 && a.departament_id==this.id_departament)


  }
sendApplication(__code:string){
  //verificar se o o tipo de dados corresponde ao solicitado

  const app=repoApps.find(a=>a.code==__code)

  if(app){
this.selectedApp.emit(app.component)
}
}
getRoles(){
  this.http.All("/departaments/user/roles").subscribe((res)=>{

    this.applicatons= res.data

    .filter((a: { type: number; departament_id: string; })=>a.type===2 && a.departament_id==this.id_departament)

console.log(res)
  },((error)=>{
    if(error.status==401){
      this.toastr.error("Não autorizado!")
    }
   else if(error.status==403){
      this.toastr.error(error.error)
    }
    else{
      this.toastr.error(error.message)

    }    console.log(error)
  }))
}
}
