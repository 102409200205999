export const  genreData=[
  {id:'M',name:"Masculino"},
{id:'F',name:"Femenino"},


]
export const  maritalStatusData=[
  {id:'Solteiro',name:"Solteiro"},
{id:'Casado',name:"Casado"},
{id:'Divórciado',name:"Divórciado"},
{id:'Viúvo',name:"Viúvo"},
]
export const  regimeData=[
  {id:'Regular',name:"Regular"},
{id:'Pós-Laboral',name:"Pós-Laboral"},
{id:'Especial',name:"Especial"}
]

export const  situationData=[
  {id:'Novo',name:"Novo"},
{id:'Transferido',name:"Transferido"},
{id:'Transferência',name:"Transferência"},
{id:'Continuante',name:"Continuante"},
{id:'Repetente',name:"Repetente"},
{id:'Desistente',name:"Desistente"},
]
export const  typeCurricularPlanData=[
  {id:'Iº Trimestre',name:"Iº Trimestre"},
{id:'IIº Trimestre',name:"IIº Trimestre"},
{id:'IIIº Trimestre',name:"IIIº Trimestre"},
{id:'Iº Semestre',name:"Iº Semestre"},
{id:'IIº Semestre',name:"IIº Semestre"},
]


export const operationType=[
  {id:'Candidatura',name:"Candidatura"},
  {id:'Matrícula',name:"Matrícula e Candidatura"},

]
