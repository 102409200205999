

    <!-- begin container-fluid -->
    <div class="container-fluid">
      <!-- begin row -->
      <div class="row">
          <div class="col-md-12 m-b-30">
              <!-- begin page title -->
              <div class="d-block d-lg-flex flex-nowrap align-items-center">
                  <div class="page-title mr-4 pr-4 ">
                      <h4> {{title}}</h4>


                  </div>
                  <div class="ml-auto d-flex align-items-center secondary-menu text-center">






                    <a class="tooltip-wrapper" data-toggle="tooltip" data-placement="top" title="" data-original-title="Listar">
                      <i class="fa fa-list btn btn-icon text-primary" (click)="backToList('studentslist')"></i> </a>
                  </div>
              </div>
              <!-- end page title -->
          </div>
      </div>




      <div class="row formavlidation-wrapper">

          <div class="col-md-12">
              <div class="card card-statistics">
                  <div class="page-title mr-4 pr-4 " style="margin:20px 0px 0px 20px ">
                      <h6 class="text-danger"> Dados Académicos </h6>
                  </div>

                  <div class="card-body">
                      <div class="tab nav-border-bottom">


                          <div class="tab-content">
                              <div class="tab-pane fade py-3 active show" id="home-02" role="tabpanel" aria-labelledby="home-02-tab">
                                  <div id="advanced-first" class="step">
                                      <!-- Step Info -->

                                      <div id="advanced-first" class="step">
                                          <!-- Step Info -->
                                              <div class="row">
                                                  <div class="col-md-12">
                                                       <i class="fa fa-home  institute"></i>
                                                    <p>     <strong>{{userData.institute}}</strong></p>
                                                  </div>
                                                <hr>
                                              </div>
                                          <div class="form-row">

                                              <div class="col-md-3 col-sm-12">
                                                  <div class="form-group">
                                                      <label class="control-label">Nome completo*</label>
                                                              <p><strong>{{userData.name}}</strong></p>
                                                                                           </div>
                                              </div>
                                              <div class="col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label class="control-label">Nome do Pai*</label>
                                                  <p>  <strong>{{userData.father_name}}</strong></p>
                                                </div>
                                            </div>
                                            <div class="col-md-3 col-sm-12">
                                              <div class="form-group">
                                                  <label class="control-label">Nome da mãe*</label>
                                                <p>  <strong>{{userData.mother_name}}</strong></p>
                                              </div>
                                          </div>
                                            <div class="col-md-3 col-sm-12">
                                              <div class="form-group">
                                                  <label class="control-label">Sala*</label>
                                                <p>  <strong>{{userData.classroom}}</strong></p>
                                              </div>
                                          </div>
                                            <div class="col-md-3 col-sm-12">
                                              <div class="form-group">
                                                  <label class="control-label">Turma*</label>
                                                <p>  <strong>{{userData.classe}}</strong></p>
                                              </div>
                                          </div>
                                              <div class="col-md-3 col-sm-12">
                                                  <div class="form-group">
                                                      <label class="control-label">Nível *</label>
                                                      <p><strong>{{userData._level}}</strong></p>
                                                  </div>
                                              </div>
                                              <div class="col-md-4 col-sm-12">
                                                  <div class="form-group">
                                                      <label class="control-label">Curso *</label>
                                                       <p><strong>{{userData.course}}</strong></p>
                                                  </div>
                                              </div>
                                          </div>
                                          <div class="form-row">

                                              <div class="col-md-3 col-sm-12">
                                                  <div class="form-group">
                                                      <label class="control-label">Ano lectivo *</label>
                                                   <p>   <strong>{{userData._year}}</strong></p>
                                                  </div>
                                              </div>

                                              <div class="col-md-3 col-sm-12">
                                                  <div class="form-group">
                                                      <label class="control-label">Período*</label>

                                                     <p> <strong>{{userData.period}}</strong></p>
                                                  </div>
                                              </div>
                                              <div class="col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label class="control-label">Máximo estudantes:</label>
                                                   <p> <strong>{{userData.max_students}}</strong></p>

                                                </div>
                                            </div>





                                          </div>


                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>

                  </div>
              </div>
          </div>
      </div>


      <div class="row formavlidation-wrapper  ">

          <div class="col-md-12">
              <div class="card card-statistics">
                  <div class="page-title mr-4 pr-4 " style="margin:20px 0px 0px 20px ">
                      <h6 class="text-danger"> Preencha os campos </h6>
                  </div>
                  <div class="card-body">


                      <div class="form-row">



                  </div>

              </div>
          </div>
      </div>



  </div>

</div>



