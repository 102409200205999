
<app-modal-container></app-modal-container>
<!-- begin container-fluid -->
<div class="container-fluid">
  <!-- begin row -->
  <div class="row">
    <div class="col-md-12 m-b-30">
      <!-- begin page title -->
      <div class="d-block d-lg-flex flex-nowrap align-items-center">
        <div class="page-title mr-4 pr-4 ">
          <h4 class="page-title"> {{currentCompnent}} </h4>

        </div>

        <div class="ml-auto d-flex align-items-center secondary-menu text-center" id="modal_shadow_bottom">
          <a  data-toggle="modal" data-target="" (click)="addClass('candidaturecreateupdate')"
            class="tooltip-wrapper" title="Adicionar">
            <i class="fa fa-plus-circle btn btn-icon text-primary"></i> </a>



          <a  class="tooltip-wrapper" data-toggle="tooltip" data-placement="top" title=""
          data-original-title="Exportar Pdf" (click)="addClass('studentslist')">
          </a>
        <a  class="tooltip-wrapper" data-toggle="tooltip" data-placement="top" title=""
          data-original-title="Exportar Excel">
          <i class="fa fa-file-pdf-o btn btn-icon text-cyan" (click)="pdf()"></i></a>

          <a  class="tooltip-wrapper" data-toggle="tooltip" data-placement="top" title=""
          data-original-title="Exportar Excel">
          <i class="fa fa-file-excel-o btn btn-icon text-cyan"></i> </a>

          <a routerLink="/registoprimariomatricula"   class="tooltip-wrapper" data-toggle="tooltip" data-placement="top" title="Registo Primário"
          data-original-title="Registo Primário">
          <i class="fa fa-file-excel-o btn btn-icon text-cyan"></i> </a>
          <a routerLink="/matrilasemestrais"  class="tooltip-wrapper" data-toggle="tooltip" data-placement="top" title="Matrículas Semestrais"
          data-original-title="Matrículas Semestrais">
          <i class="fa fa-file-excel-o btn btn-icon text-cyan"></i> </a>
        </div>
      </div>
      <!-- end page title -->
    </div>
  </div>
    <hr>
<app-filetr-according></app-filetr-according>
  <div class="row">
    <div class="col-12 col-lg-12">
      <div class="card card-statistics">

        <div class="card-body">

          <div class="  table-responsive">
                <div class="row float-right p-1">
                  <div class="col-md-12">
                    <input type="text" [(ngModel)]="filternName" class="form-control" name="filter" (keyup)="filter($event)">
                  </div>
                </div>

            <table id="listStudents"
              class="table table-bordered  table-striped table-sm">



              <thead class="text-light bg-info">
                <tr>
                  <th class="header" scope="col" width="10%">Ordem</th>
                  <th class="header" scope="col" width="40%">Nome completo</th>

                  <th class="header" scope="col" width="20%">Bilhete</th>
                  <th class="header" scope="col" width="40%">Curso</th>
                  <th class="header" scope="col" width="40%">Nível</th>
                  <th class="header" scope="col" width="40%">Período</th>
                  <th class="header" scope="col" width="40%">Sala /p Exame</th>
                  <th class="header" scope="col" width="40%">Gênero</th>

                  <th class="header" scope="col" width="40%" *ngIf="!print">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let turma of Students  | tableFilter: filternName;index as i ">
                  <td>{{1+i}}</td>
                  <td>{{turma?.name}}</td>

                  <td>{{turma?.identity_card}}</td>

                  <td>{{turma?.course}}</td>
                  <td>{{turma?._level}}</td>
                  <td>{{turma?.period}}</td>
                  <td>{{turma?.classroom}}</td>
                  <td>{{turma?.genre}}</td>
                  <td *ngIf="!print"><i class="fa fa-eye" (click)="sendId(turma?.candidature_id,'candidature')"></i> | <i class="fa fa-edit" (click)="sendId(turma?.candidature_id,'candidaturecreateupdate')"></i></td>
                 </tr>
              </tbody>
            </table>
              <strong>     Total:{{totalRows}} | Page:  {{lastPage}}</strong>
            <ul class="pagination">

              <li class="page-item"><a class="page-link" href="#" (click)="previousPage()">Previous</a></li>
            <!--  <li class="page-item" *ngFor="let pag of [].constructor(totalPages);let i=index"><a class="page-link" href="#" (click)="SetnextPageOrpreviousPage(i)">{{i}}</a></li>
              -->
              <li class="page-item"><a class="page-link" href="#" (click)="nextPageOrpreviousPage()">Next</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</div>
<!-- end container-fluid -->
