import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { CurricularPlan } from 'src/app/models/edu/curricular-plan.model';
import { HttpRepoService } from 'src/app/repository/http/http/http_repo.service';
import { DiaryDataService } from 'src/app/services/base/diary-data.service';
import { typeCurricularPlanData } from 'src/app/static-data/genre.data';
import { uuid } from 'uuidv4';
@Component({
  selector: 'app-add-update-curricular-plan',
  templateUrl: './add-update-curricular-plan.component.html',
  styleUrls: ['./add-update-curricular-plan.component.css']
})
export class AddUpdateCurricularPlanComponent implements OnInit {
  title='Novo P. Curricular'
  @Output() currentView : EventEmitter<string> = new EventEmitter()
  @Input() id=''
  formSubject:CurricularPlan ={}
 subjectsName:any
  courseNames:any
  levelsNames:any
  typeNames=typeCurricularPlanData
  seletedTypeNames:any
  seletedLevelsNames:any
  selectedSubject:any
  seletedCourseNames:any

  dropdownSettings: IDropdownSettings |any
  constructor(private http:HttpRepoService, private toastr:ToastrService) { }
  ngOnChanges(changes: SimpleChanges): void {
    // idUser.currentValue
  //console.log(changes)

  if(this.id!=''){


  }

  }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllTetxt: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.getMyInstitute()
    this.getMyOrg()
        //
        this.getSubjectList()
        this.getLevesList()
        this.getCoursesList()
        if(this.id==''){
          this.getUUID()
          this.formSubject.diary=parseInt(DiaryDataService.getDiary())
        }else{
        //  this.getClass()
          //this.getClassRoomList()

        }
  }
  getSubjectList(){
    this.http.All("/subjects").subscribe((res)=>{
      this.subjectsName=res.data

    },((error)=>{
      if(error.status==401){
        this.toastr.error("Não autorizado!")
      }
     else if(error.status==403){
        this.toastr.error(error.error)
      }
      else{
        this.toastr.error(error.message)

      }    console.log(error)
    }))
  }


  getCoursesList(){
    this.http.All("/courses").subscribe((res)=>{
      this.courseNames=res.data

    },((error)=>{
      if(error.status==401){
        this.toastr.error("Não autorizado!")
      }
     else if(error.status==403){
        this.toastr.error(error.error)
      }
      else{
        this.toastr.error(error.message)

      }    console.log(error)
    }))
  }



  getLevesList(){
    this.http.All("/levels").subscribe((res)=>{
      this.levelsNames=res.data
    },((error)=>{
      if(error.status==401){
        this.toastr.error("Não autorizado!")
      }
     else if(error.status==403){
        this.toastr.error(error.error)
      }
      else{
        this.toastr.error(error.message)

      }    console.log(error)
    }))
  }

getUUID(){
  this.http.All("/uuids").subscribe((res)=>{
    this.formSubject.id=res.data

  },((error)=>{
    if(error.status==401){
      this.toastr.error("Não autorizado!")
    }
   else if(error.status==403){
      this.toastr.error(error.error)
    }
    else{
      this.toastr.error(error.message)

    }    console.log(error)
  }))
}
getMyOrg(){
  this.http.All("/institutes/org").subscribe((res)=>{
   // this.formSubject.university_id=res.data.id

  },((error)=>{
    if(error.status==401){
      this.toastr.error("Não autorizado!")
    }
   else if(error.status==403){
      this.toastr.error(error.error)
    }
    else{
      this.toastr.error(error.message)

    }    console.log(error)
  }))
}
getMyInstitute(){
  this.http.All("/institutes/my-institute").subscribe((res)=>{
    this.formSubject.institute_id=res.data.id

  },((error)=>{
    if(error.status==401){
      this.toastr.error("Não autorizado!")
    }
   else if(error.status==403){
      this.toastr.error(error.error)
    }
    else{
      this.toastr.error(error.message)

    }    console.log(error)
  }))
}

save(){

  this.http.post(this.formSubject,"/curricularplans").subscribe((res)=>{

//console.log(res)
console.log(res)
if(res.data){
  this.toastr.success(res.message)
  this.id=''
  //this.userForm={}
  console.log(res)
//  this.backToList('studentslist')
}
else{
  this.toastr.error(res.message)

}
  },((error)=>{
    if(error.status==401){
      this.toastr.error(error.message)
    }
   else if(error.status==403){
      this.toastr.error(error.message)
    }
    else{
      this.toastr.error(error.message)

    }    console.log(error)
  }))
}

saveCandidature(){

  this.http.post(this.formSubject,"/candidatures").subscribe((res)=>{

//console.log(res)
console.log(res)
if(res.data){
  this.toastr.success(res.message)
  this.id=''
  //this.userForm={}
  console.log(res)
//  this.backToList('studentslist')
}
else{
  this.toastr.error(res.message)

}
  },((error)=>{
    if(error.status==401){
      this.toastr.error(error.message)
    }
   else if(error.status==403){
      this.toastr.error(error.message)
    }
    else{
      this.toastr.error(error.message)

    }    console.log(error)
  }))
}

  refress(){

  }

backToList(code:string){
  this.currentView.emit(code);


try{

}catch(ex){

}
  //$('#'+ev).toggleClass('show')
 }

 onSelect(items:any,op:number){

    switch(op){
      case 1:
        this.formSubject.subject_id=items.id

        break;
        case 2:
          this.formSubject.level_id=items.id


          break;
          case 3:
           this.formSubject.curricular_type=items.id

            break;
            case 4:
             this.formSubject.course_id=items.id
              break;


    }

}
}
