import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import User from 'src/app/models/user';
import { HttpRepoService } from 'src/app/repository/http/http/http_repo.service';
import { DiaryDataService } from 'src/app/services/base/diary-data.service';
import { genreData, situationData, maritalStatusData, regimeData, operationType } from 'src/app/static-data/genre.data';
import { periodsData } from 'src/app/static-data/periods.data';
import { userTypeData } from 'src/app/static-data/user.type.data';

@Component({
  selector: 'app-candidatures-add-update',
  templateUrl: './candidatures-add-update.component.html',
  styleUrls: ['./candidatures-add-update.component.css']
})
export class CandidaturesAddUpdateComponent implements OnInit {
operation=''

  @Output() currentView : EventEmitter<string> = new EventEmitter()
  @Input() id=''
  title='Nova'
  preClasses:any
userForm:User={}
genreNames=genreData
situationNames=situationData
  maritalStatusNames=maritalStatusData
  regimeNames=regimeData
  levelsNames:any
  courseNames:any
  countryNames:any
  provincesNames:any
  municipaliesNames:any
  yearsNames:any
  typesNames=userTypeData.filter(t=>t.id=='Aluno')
  classesNames:any
  periodsNames=periodsData
  operationTypes=operationType
  seletedOperationTypes:any
dropdownSettings: IDropdownSettings |any
//sletede
seletedPeriodsNames:any
selectedClasse_name_id:any
seletedCourseNames:any
seletedCevelsNames:any
seletedmunicipaliesNames:any
seletedProvinceNames:any
seletedYearsNames:any
seletedclassesNames:any
seletedtypesNames:any
seletedGenreNames:any
selectedMaritalStatusNames:any
selectedRegimeNames:any
selectedSituationNames:any
selectedCountry_id:any
selectedProvince_id:any
studentSubjects=[]

classromNames:any

seletedClassromNames:any

  constructor(private http:HttpRepoService, private toastr:ToastrService) { }
  ngOnChanges(changes: SimpleChanges): void {
    // idUser.currentValue
  //console.log(changes)

  if(this.id!=''){
    this.getClass()

  }

  }
  ngOnInit(): void {
      this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllTetxt: 'UnSelect All',
   //   itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.getCountryList()

    this.getYearsList()

    this.getMyInstitute()
    this.getClassRoomListAll()
this.getMyOrg()
    //
    this.getLevesList()
    this.getCoursesList()
    if(this.id==''){
      this.getUUID()
      this.userForm.diary=parseInt(DiaryDataService.getDiary())
    }else{
      this.getClass()
      //this.getClassRoomList()

    }


   // this.userForm.id=uuid()

  }

backToList(code:string){
    this.currentView.emit(code);


  try{

  }catch(ex){

  }
    //$('#'+ev).toggleClass('show')
   }

  getClassRoomListAll(){
    this.http.All("/classrooms").subscribe((res)=>{
      this.classromNames=res.data

    },((error)=>{
      if(error.status==401){
        this.toastr.error("Não autorizado!")
      }
     else if(error.status==403){
        this.toastr.error(error.error)
      }
      else{
        this.toastr.error(error.message)

      }    console.log(error)
    }))
  }


   getCoursesList(){
    this.http.All("/courses").subscribe((res)=>{
      this.courseNames=res.data

    },((error)=>{
      if(error.status==401){
        this.toastr.error("Não autorizado!")
      }
     else if(error.status==403){
        this.toastr.error(error.error)
      }
      else{
        this.toastr.error(error.message)

      }    console.log(error)
    }))
  }



  getLevesList(){
    this.http.All("/levels").subscribe((res)=>{
      this.levelsNames=res.data
    },((error)=>{
      if(error.status==401){
        this.toastr.error("Não autorizado!")
      }
     else if(error.status==403){
        this.toastr.error(error.error)
      }
      else{
        this.toastr.error(error.message)

      }    console.log(error)
    }))
  }
  getCountryList(){
    this.http.All("/countries").subscribe((res)=>{
      this.countryNames=res.data

    },((error)=>{
      if(error.status==401){
        this.toastr.error("Não autorizado!")
      }
     else if(error.status==403){
        this.toastr.error(error.error)
      }
      else{
        this.toastr.error(error.message)

      }    console.log(error)
    }))
  }
  getClassRoomList(){
    this.http.post({course_id:this.userForm.course_id,period:this.userForm.period,level_id:this.userForm.level_id},"/classes/bylcassecourseperiod").subscribe((res)=>{
      this.classesNames=res.data
      console.log(res.data)
     // this.preClasses=this.classesNames

    },((error)=>{
      if(error.status==401){
        this.toastr.error("Não autorizado!")
      }
     else if(error.status==403){
        this.toastr.error(error.error)
      }
      else{
        this.toastr.error(error.message)

      }    console.log(error)
    }))
  }

 async getClass(){
    this.http.All("/candidatures/"+this.id).subscribe((res)=>{
      const data=res.data

      this.userForm=data
      console.log(data)
      if(data.classe !=null){
        this.operation="Matrícula"
      }
      else{
        this.operation='Candidatura'
      }
      this.getProvincesList()
      this.getMunicipaliesList()

      //seletedlists
this.refress()

    },((error)=>{
      if(error.status==401){
        this.toastr.error("Não autorizado!")
      }
     else if(error.status==403){
        this.toastr.error(error.error)
      }
      else{
        this.toastr.error(error.message)

      }    console.log(error)
    }))
  }

saveCandidature(){

  this.http.post(this.userForm,"/candidatures").subscribe((res)=>{

//console.log(res)
console.log(res)
if(res.data){
  this.toastr.success(res.message)
  this.id=''
  //this.userForm={}
  console.log(res)
//  this.backToList('studentslist')
}
else{
  this.toastr.error(res.message)

}
  },((error)=>{
    if(error.status==401){
      this.toastr.error(error.message)
    }
   else if(error.status==403){
      this.toastr.error(error.message)
    }
    else{
      this.toastr.error(error.message)

    }    console.log(error)
  }))
}


  save(){

    this.http.post(this.userForm,"/students").subscribe((res)=>{

  //console.log(res)
  console.log(res)
  if(res.data){
    this.toastr.success(res.message)
    this.id=''
    //this.userForm={}
    console.log(res)
    this.backToList('studentslist')
  }
  else{
    this.toastr.error(res.message)

  }
    },((error)=>{
      if(error.status==401){
        this.toastr.error(error.message)
      }
     else if(error.status==403){
        this.toastr.error(error.message)
      }
      else{
        this.toastr.error(error.message)

      }    console.log(error)
    }))
  }

  getYearsList(){
    this.http.All("/anosletivos").subscribe((res)=>{
      this.yearsNames=res.data
    },((error)=>{
      if(error.status==401){
        this.toastr.error("Não autorizado!")
      }
     else if(error.status==403){
        this.toastr.error(error.error)
      }
      else{
        this.toastr.error(error.message)

      }    console.log(error)
    }))
  }

getMunicipaliesList(){
  this.http.All("/municipalies/bycountry/"+this.userForm.pronvice_id).subscribe((res)=>{
    this.municipaliesNames=res.data

  },((error)=>{
    if(error.status==401){
      this.toastr.error("Não autorizado!")
    }
   else if(error.status==403){
      this.toastr.error(error.error)
    }
    else{
      this.toastr.error(error.message)

    }    console.log(error)
  }))
}

getProvincesList(){
  this.http.All("/provinces/country/"+ this.userForm.country_id).subscribe((res)=>{
    this.provincesNames=res.data
  },((error)=>{
    if(error.status==401){
      this.toastr.error("Não autorizado!")
    }
   else if(error.status==403){
      this.toastr.error(error.error)
    }
    else{
      this.toastr.error(error.message)

    }    console.log(error)
  }))
}
getUUID(){
  this.http.All("/uuids").subscribe((res)=>{
    this.userForm.id=res.data

  },((error)=>{
    if(error.status==401){
      this.toastr.error("Não autorizado!")
    }
   else if(error.status==403){
      this.toastr.error(error.error)
    }
    else{
      this.toastr.error(error.message)

    }    console.log(error)
  }))
}
getMyOrg(){
  this.http.All("/institutes/org").subscribe((res)=>{
    this.userForm.university_id=res.data.id

  },((error)=>{
    if(error.status==401){
      this.toastr.error("Não autorizado!")
    }
   else if(error.status==403){
      this.toastr.error(error.error)
    }
    else{
      this.toastr.error(error.message)

    }    console.log(error)
  }))
}
getMyInstitute(){
  this.http.All("/institutes/my-institute").subscribe((res)=>{
    this.userForm.institute_id=res.data.id

  },((error)=>{
    if(error.status==401){
      this.toastr.error("Não autorizado!")
    }
   else if(error.status==403){
      this.toastr.error(error.error)
    }
    else{
      this.toastr.error(error.message)

    }    console.log(error)
  }))
}
onSelect(items:any,op:number){
  /*
   course_id?:number ;
    classe_name_id?:number 1;
    classeroom_id?:number 2;
    diary?:number;
    ano_letivo_id?:number;
    level_id?:number 3;
    max_students?:number;
    period?:string;*/
    switch(op){
      case 1:
        this.userForm.country_id=items.id
        this.getProvincesList()
        break;
        case 2:
          this.userForm.pronvice_id=items.id

    this.getMunicipaliesList()
          break;
          case 3:
            this.userForm.municipaly_id=items.id

            break;
            case 4:
              this.userForm.ano_letivo_id=items.id
              break;
              case 5:
                this.userForm.user_type=items.id
                break;
                case 6:
                 this.userForm.classe_id=items.id
                  break;
                  case 7:
                     this.userForm.course_id=items.id
                     break;
                     case 8:
                     this.userForm.level_id=items.id
                     break;
                     case 9:
                      this.userForm.marital_status=items.id
                      break;
                     case 10:
                      this.userForm.regime=items.id
                      break;
                      case 11:
                        this.userForm.genre=items.id
                        break;
                        case 12:
                        this.userForm.situation=items.id
                        break;
                        case 13:
                        this.userForm.period=items.name
                        this.getClassRoomList()
                       //this.classesNames= this.preClasses.filter((c: { course_id: string | undefined; level_id: string | undefined; period: string | undefined; })=>c.course_id==this.userForm.course_id&&c.level_id==this.userForm.level_id&&c.period==this.userForm.period)
                        break;
                        case 15:
                          this.operation=items.id

                         //this.classesNames= this.preClasses.filter((c: { course_id: string | undefined; level_id: string | undefined; period: string | undefined; })=>c.course_id==this.userForm.course_id&&c.level_id==this.userForm.level_id&&c.period==this.userForm.period)
                          break;
                          case 16:
                          this.userForm.classroom_id=items.id

                         //this.classesNames= this.preClasses.filter((c: { course_id: string | undefined; level_id: string | undefined; period: string | undefined; })=>c.course_id==this.userForm.course_id&&c.level_id==this.userForm.level_id&&c.period==this.userForm.period)
                          break;



    }

}
async refress(){
  this.getClassRoomList()
  this.selectedRegimeNames=this.getSeleted(this.regimeNames,this.userForm.regime,'s')
  this.selectedSituationNames=this.getSeleted(this.situationNames,this.userForm.situation,'s')
  this.seletedCevelsNames=this.getSeleted(this.levelsNames,this.userForm.level_id,'n')
  this.seletedCourseNames=this.getSeleted(this.courseNames,this.userForm.course_id,'n')

  this.selectedClasse_name_id=this.getSeleted(this.countryNames,this.userForm.country_id,'n')
  this.selectedCountry_id=this.getSeleted(this.countryNames,this.userForm.country_id,'n')



  this.seletedmunicipaliesNames=this.getSeleted(this.municipaliesNames,this.userForm.municipaly_id,'n')
  this.seletedYearsNames=this.getSeleted(this.yearsNames,this.userForm.ano_letivo_id,'n')
  this.seletedclassesNames=this.getSeleted(this.classesNames,this.userForm.classe_id,'n')
  this.seletedtypesNames=this.getSeleted(this.typesNames,this.userForm.user_type,'n')
  this.seletedGenreNames=this.getSeleted(this.genreNames,this.userForm.genre,'n')
  this.selectedProvince_id=this.getSeleted(this.provincesNames,this.userForm.pronvice_id,'n')
  this.selectedMaritalStatusNames=this.getSeleted(this.maritalStatusNames,this.userForm.marital_status,'n')
  this.seletedPeriodsNames=this.getSeleted(this.periodsNames,this.userForm.period,'s')
}
getSeleted(data:any[],id:string|undefined,skype:string){
  try{
    let seleted:any
    if(skype=='s'){
       seleted= data.find((cn: { name: string | undefined; })=>cn.name== id)
    }else{
       seleted= data.find((cn: { id: string | undefined; })=>cn.id== id)
    }

    //let result={id:seleted.id||'',name:seleted.name||''}

 /*delete seleted.created_at
 delete seleted.diary
 delete seleted.updated_at*/
    console.log(seleted)
 return [ {id:seleted.id,name:seleted.name}]
  }
  catch(ex:any){
    console.log(ex.message)
    return []
  }

}
 mergue(){


  const subj=this.seletedCourseNames.map( (s: { id: string; name: string; })=>{

    return {subject_id:s.id,name:s.name,classe_id:this.userForm.classe_id,type:'ICS'}
  })
  //this.studentSubjects.push(subj)
  console.log(subj)
}

}
