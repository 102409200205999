import { Component, EventEmitter, Input, OnInit, Output, Pipe, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpRepoService } from 'src/app/repository/http/http/http_repo.service';
import { PaginationDataService } from 'src/app/services/base/pagination-data.service';
import { PdfWithObjectService } from 'src/app/services/reports/edu/pdf-with-object.service';

@Component({
  selector: 'app-classes-details',
  templateUrl: './classes-details.component.html',
  styleUrls: ['./classes-details.component.css']
})
export class ClassesDetailsComponent implements OnInit {
  title='Detalhes da turma'
  @Input() id=''
  @Output() currentView : EventEmitter<string> = new EventEmitter()

  @Pipe({
    name: 'tableFilter'
  })
  logo_header:any
   currentCompnent='Matrículas'
filternName=''
  Students:any
  studentsBruteData:any
pages:any
totalPages=0
pageNumber=0
totalRows=0
lastPage=1
name: any;
print=false
 data:string[]=[]
  classData:any
  constructor(private http:HttpRepoService,private toastr:ToastrService,private paginate:PaginationDataService,private PDF:PdfWithObjectService) { }

  ngOnInit(): void {
    this. getStudentsData()
    this.getMyInstitute()
  }
  ngOnChanges(changes: SimpleChanges): void {
    // idUser.currentValue
  //console.log(changes)


    this.getClass()


  }
  async getClass(){
    this.http.All("/classes/"+this.id).subscribe((res)=>{

      this.classData=res.data

      //seletedlists


    },((error)=>{
      if(error.status==401){
        this.toastr.error("Não autorizado!")
      }
     else if(error.status==403){
        this.toastr.error(error.error)
      }
      else{
        this.toastr.error(error.message)

      }    console.log(error)
    }))
  }

  backToList(code:string){
    this.currentView.emit(code);


  try{

  }catch(ex){

  }
    //$('#'+ev).toggleClass('show')
   }


   pdf(){
    //var table_elt = document.getElementById("my-table-id");
        /*const ws=XLSX.utils.json_to_sheet(this.studentsBruteData)
        const wb=XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb,ws,'fatura.')
        XLSX.writeFile(wb,'fatura.xlsx')
    const data=Object.entries(this.studentsBruteData)
    console.log(data)*/
    let data=this.studentsBruteData.map((item: { id: string; name: string; course:string;identity_card:string;_level:string;classe:string;period:string;genre:string;})=>{
      return {id:item.id,name:item.name,identity_card:item.identity_card,course:item.course,_level:item._level,classe:item.classe,period:item.period,genre:item.genre}
    })
    this.print=true

    //lista geral
    let resultList=data.map((d: { name: string;identity_card:string; course: string;_level:string;classe:string;period:string;genre:string; })=>[d.name,d.identity_card,d.course,d._level,d.classe,d.period,d.genre])
    let headerList=[['Nome completo','Bilhete de Identidade','Curso','Frequência','Turma','Período','Gênero']]
    let {headerByClasses ,listByClasses}=this.PDF.getByClassePeriod(this.studentsBruteData)
    let pdfDataList={
      resultList,
      listByClasses

    }
    let pdfDataHeader={
      headerList,
      headerByClasses
    }
    this.PDF.pdf(pdfDataHeader,pdfDataList,'#listStudents',this.logo_header.data)
      }

       private getStudentsData() {
        try{
          this.http.All('/students/class/'+this.id)
          .subscribe((res)=>{


              if(res.data.length>0){

                this.studentsBruteData =res.data
                this.pages=this.paginate.paginate( this.studentsBruteData)
                this.Students=this.pages.items
                this.totalRows=this.pages.total
                this.totalPages= this.pages.totalPages
                console.log(this.paginate.paginate( this.studentsBruteData))
    /* var value = $(this).val().toLowerCase();
        $("#myList li").filter(function() {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
        });*/

              }
              else{}
          //  this.topRightToast('Sem dados!')
          },((error)=>{
            if(error.status==401){
              this.toastr.error("Não autorizado!")
            }
           else if(error.status==403){
              this.toastr.error(error.error)
            }
            else{
              this.toastr.error(error.message)

            }    console.log(error)
          }));

        }catch(ex:any){

        this.toastr.error("Erro")

        }


      }
      nextPageOrpreviousPage(){

        this.pages=this.paginate.nextPageOrpreviousPage(this.studentsBruteData,this.pages.nextPage)
        this.Students=this.pages.items
        this.lastPage=this.pages.nextPage
      }
      previousPage(){
        if(this.lastPage>1){
          this.lastPage= this.lastPage-1
        }


        this.pages=this.paginate.nextPageOrpreviousPage(this.studentsBruteData,this.lastPage)
        this.Students=this.pages.items
      }
      SetnextPageOrpreviousPage(_pag=0){

        this.pages=this.paginate.nextPageOrpreviousPage(this.studentsBruteData,_pag)
        this.Students=this.pages.items
        console.log( this.Students)
      }
      filter(ev:any){
        console.log(ev.target.value)
    let data =this.paginate.paginate(this.paginate.filterData(this.studentsBruteData,ev.target.value))

    //this.users= data.items
      }
      exportExcel() {
        this.getExcel()
    //this.excel.gerarExcel(this.studentsBruteData,'estudantes', this.logo_header.data)
      }

      getMyInstitute(){
        this.http.All("/institutes/my-institute").subscribe((res)=>{
         this.logo_header=res.data.logo_header
         console.log(this.logo_header)

        },((error)=>{
          if(error.status==401){
            this.toastr.error("Não autorizado!")
          }
         else if(error.status==403){
            this.toastr.error(error.error)
          }
          else{
            this.toastr.error(error.message)

          }    console.log(error)
        }))
      }


      getExcel(){
        this.http.All("/excelreportingstudntes").subscribe((res)=>{


        },((error)=>{
          if(error.status==401){
            this.toastr.error("Não autorizado!")
          }
         else if(error.status==403){
            this.toastr.error(error.error)
          }
          else{
            this.toastr.error(error.message)

          }    console.log(error)
        }))
      }

}
