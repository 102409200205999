
<div class="dock-menu">
  <div class="background-dock">
      <nav class="main-nav">
          <ul class="main-nav-list">


              <li class="dock-icons" *ngFor="let app of apps" (click)="sendID(app.id)"><img src="./assets/layout-mac/images/apps/{{app.id}}.png" alt="Appstore" title="{{app.name}}"></li>

          </ul>
      </nav>
  </div>
</div>
