export const  myApps=[
{id:1,name:"Dep. Tecnologias",label:"Dep. Tecnologias",code:"depti",icon:"",description:'Administração do sistemas em geral'},
{id:2,name:"Secretaria Geral",label:"Secretaria Geral",code:"sg",icon:"",description:'Gestão de candidaturas,matriculas e pagamentos...'},
{id:3,name:"Secretaria Pedagógica",label:"Secr. Académica",code:"sa",icon:"",description:'Administração do sistemas em geral'},
{id:4,name:"Recursos Humanos",label:"Contabilidade",code:"cd",icon:"",description:'Gestão de candidaturas,matriculas e pagamentos...'},
{id:5,name:"Tesouraria",label:"Tesouraria",code:"ts",icon:"",description:'Administração do sistemas em geral'},
{id:6,name:"Docente",label:"Dep. Académico",code:"depac",icon:"",description:'Gestão de candidaturas,matriculas e pagamentos...'},
{id:7,name:"Arquivo",label:"Recepção",code:"rpc",icon:"",description:'Administração do sistemas em geral'},
{id:8,name:"Estudante",label:"Recepção",code:"rpc",icon:"",description:'Administração do sistemas em geral'},

]
