

    <!-- begin container-fluid -->
    <div class="container-fluid">
      <!-- begin row -->
      <div class="row">
          <div class="col-md-12 m-b-30">
              <!-- begin page title -->
              <div class="d-block d-lg-flex flex-nowrap align-items-center">
                  <div class="page-title mr-4 pr-4 ">
                      <h4> {{title}}</h4>


                  </div>
                  <div class="ml-auto d-flex align-items-center secondary-menu text-center">






                    <a class="tooltip-wrapper" data-toggle="tooltip" data-placement="top" title="" data-original-title="Listar">
                      <i class="fa fa-list btn btn-icon text-primary" (click)="backToList('payDatalist')"></i> </a>
                  </div>
              </div>
              <!-- end page title -->
          </div>
      </div>




      <div class="row formavlidation-wrapper">

          <div class="col-md-12">
              <div class="card card-statistics">
                  <div class="page-title mr-4 pr-4 " style="margin:20px 0px 0px 20px ">
                      <h6 class="text-danger"> Dados Académicos </h6>
                  </div>

                  <style>

                  </style>
                  <div class="card-body">
                      <div class="tab nav-border-bottom">


                          <div class="tab-content">
                              <div class="tab-pane fade py-3 active show" id="home-02" role="tabpanel" aria-labelledby="home-02-tab">
                                  <div id="advanced-first" class="step">
                                      <!-- Step Info -->

                                      <div id="advanced-first" class="step">
                                          <!-- Step Info -->
                                              <div class="row">
                                                  <div class="col-md-12">
                                                       <i class="fa fa-file-pdf-o btn btn-icon text-cyan institute"  (click)="pdf(contentToConvert)" ></i>
                                                    <p>   </p>
                                                  </div>
                                                <hr>
                                              </div>

                    <fieldset id="myContent" #contentToConvert style="width: 210mm;">
                      <div class="invoice-box rtl">
                        <div class="row">
                          <div class="col-md-5" style="font-size: 10px;line-height: 4px;">
                            <div class="text-right">

                              <p> <img src="{{logo}}" alt="" width="70"></p>
                            <p>{{insttute?.name}}</p>
                            <p>Cliente: {{insttute?.nif}}</p>
                            <p>Curso: {{insttute?.phone}}</p>
                            <p>Turma: {{insttute?.email}}</p>
                            <p>Classe: {{insttute?.email}}</p>

                             </div>
                          </div>
                          <div class="col-md-2" style="font-size: 10px;">
                            <div class="text-center">
                              <strong>FATURA Nº 32849449490/2023</strong>
                            </div>
                            </div>
                          <div class="col-md-5" style="font-size: 10px;line-height: 4px;">
                            <div class="text-left">

                              <p> <img src="{{logo}}" alt="" width="70"></p>
                            <p>{{insttute?.name}}</p>
                            <p>NIF: {{insttute?.nif}}</p>
                            <p>Telefone: {{insttute?.phone}}</p>
                            <p>Email: {{insttute?.email}}</p>
                             <p>Ensino Particular</p>

                             </div>
                          </div>



                        </div>
                        <table
                        class="table table-bordered  table-striped table-sm">



                        <thead class="text-light bg-info">
                          <tr>
                            <th class="header" scope="col" width="10%">Ordem</th>
                            <th class="header" scope="col" width="40%">Serviço</th>
                            <th class="header" scope="col" width="40%">Quantidade</th>
                            <th class="header" scope="col" width="20%">Total</th>



                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let turma of payData  index as i ">
                            <td>{{1+i}}</td>
                            <td>{{turma?.name}} /{{turma?.month}}</td>
                            <td>{{turma?.amount}}</td>


                            <td>{{turma?.total| number:'1.2-2'}}</td>

                            </tr>
                        </tbody>
                      </table>
                      </div>



                      <hr>


                    </fieldset>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>

                  </div>
              </div>
          </div>
      </div>


      <div class="row formavlidation-wrapper  ">

          <div class="col-md-12">
              <div class="card card-statistics">
                  <div class="page-title mr-4 pr-4 " style="margin:20px 0px 0px 20px ">
                      <h6 class="text-danger"> Preencha os campos </h6>
                  </div>
                  <div class="card-body">


                      <div class="form-row">



                  </div>

              </div>
          </div>
      </div>



  </div>

</div>



