import { CandidaturesAddUpdateComponent } from "src/app/components/apps/app/edu/candidatures/candidatures-add-update/candidatures-add-update.component";
import { CandidaturesDetailsComponent } from "src/app/components/apps/app/edu/candidatures/candidatures-details/candidatures-details.component";
import { CandidaturesListComponent } from "src/app/components/apps/app/edu/candidatures/candidatures-list/candidatures-list.component";
import { CandidaturesComponent } from "src/app/components/apps/app/edu/candidatures/candidatures/candidatures.component";
import { ClassAddUpdateComponent } from "src/app/components/apps/app/edu/classes/class-add-update/class-add-update.component";
import { ClassesDetailsComponent } from "src/app/components/apps/app/edu/classes/classes-details/classes-details.component";
import { ClassesListComponent } from "src/app/components/apps/app/edu/classes/classes-list/classes-list.component";
import { ClassesComponent } from "src/app/components/apps/app/edu/classes/classes.component";
import { AddUpdateCurricularPlanComponent } from "src/app/components/apps/app/edu/curricularPlan/add-update-curricular-plan/add-update-curricular-plan.component";
import { CurricularPlanComponent } from "src/app/components/apps/app/edu/curricularPlan/curricular-plan/curricular-plan.component";
import { DefaultDashboardComponent } from "src/app/components/apps/app/edu/dashboard/default-dashboard/default-dashboard.component";
import { StudentsAddUpdateComponent } from "src/app/components/apps/app/edu/students/students-add-update/students-add-update.component";
import { StudentsDetailsComponent } from "src/app/components/apps/app/edu/students/students-details/students-details.component";
import { StudentsListComponent } from "src/app/components/apps/app/edu/students/students-list/students-list.component";
import { StudentsComponent } from "src/app/components/apps/app/edu/students/students/students.component";
import { SubjectsAddUpdateComponent } from "src/app/components/apps/app/edu/subjects/subjects-add-update/subjects-add-update.component";
import { SubjectsComponent } from "src/app/components/apps/app/edu/subjects/subjects/subjects.component";
import { AddUpdatePaymentComponent } from "src/app/components/apps/app/sales/payments/add-update-payment/add-update-payment.component";
import { PaymentDetailsComponent } from "src/app/components/apps/app/sales/payments/payment-details/payment-details.component";
import { PaymentListComponent } from "src/app/components/apps/app/sales/payments/payment-list/payment-list.component";
import { PaymentsComponent } from "src/app/components/apps/app/sales/payments/payments/payments.component";
import { LoginComponent } from "src/app/components/apps/system/auth/login/login.component";
import { BootComponent } from "src/app/components/apps/system/boot/boot.component";
import { UserAddUpdateComponent } from "src/app/components/apps/system/users/user-add-update/user-add-update.component";
import { UserDetailsComponent } from "src/app/components/apps/system/users/user-details/user-details.component";
import { UsersListComponent } from "src/app/components/apps/system/users/users-list/users-list.component";
import { UsersComponent } from "src/app/components/apps/system/users/users.component";
import { MainDashboardPrimaryComponent } from "src/app/shared/layout/primary/main-dashboard-primary/main-dashboard-primary.component";
import { MainWin11Component } from "src/app/shared/layout/theme-w11/main-win11/main-win11.component";

export const repoApps=[


  {id:1,name:'Tema Primário',code:'themeprimary',component:MainDashboardPrimaryComponent,auth_required:true,type:1,departament_id:''},
  {id:2,name:'Utilizadores',code:'users',component:UsersComponent,auth_required:true,type:2,departament_id:'b284ee6d-8f7d-47fb-9445-b5937ca8456a'},
  {id:3,name:'Novo utilizador',code:'newupdateusers',component:UserAddUpdateComponent,auth_required:true,type:1,departament_id:'b284ee6d-8f7d-47fb-9445-b5937ca8456a'},
  {id:4,name:'Utilizador',code:'user',component:UserDetailsComponent,auth_required:true,type:1,departament_id:'b284ee6d-8f7d-47fb-9445-b5937ca8456a'},
 // {id:5,name:'Lista de utilizadors',code:'userlist',component:UsersListComponent,auth_required:true,type:2,departament_id:2},
  {id:5,name:'Autenticação',code:'login',component:LoginComponent,auth_required:false,type:1,departament_id:''},
  {id:6,name:'Boot',code:'boot',component:BootComponent,auth_required:false,type:1,departament_id:''},
  {id:2,name:'Dashboard Primário',code:'defaultdashboard',component:DefaultDashboardComponent,auth_required:true,type:1,departament_id:'0ac1fae3-a045-4f8c-937c-7c8cf46e8401'},

  //edu

{id:2,name:'Turmas',code:'classes',component:ClassesComponent,auth_required:true,type:2,departament_id:'49090a9b-1444-44cd-a999-ec76e5919e2e'},
{id:2,name:'Turma',code:'classe',component:ClassesDetailsComponent,auth_required:true,type:1,departament_id:'49090a9b-1444-44cd-a999-ec76e5919e2e'},
{id:2,name:'Novo turma',code:'newupdateclass',component:ClassAddUpdateComponent,auth_required:true,type:1,departament_id:'49090a9b-1444-44cd-a999-ec76e5919e2e'},

{id:2,name:'Lista turmas',code:'classeslist',component:ClassesListComponent,auth_required:true,type:1,departament_id:'49090a9b-1444-44cd-a999-ec76e5919e2e'},
{id:2,name:'Estudantes',code:'students',component:StudentsComponent,auth_required:true,type:2,departament_id:'49090a9b-1444-44cd-a999-ec76e5919e2e'},
{id:2,name:'Estudantes',code:'student',component:StudentsDetailsComponent,auth_required:true,type:2,departament_id:'49090a9b-1444-44cd-a999-ec76e5919e2e'},

{id:2,name:'Novo estudante',code:'studentcreateupdate',component:StudentsAddUpdateComponent,auth_required:true,type:2,departament_id:'49090a9b-1444-44cd-a999-ec76e5919e2e'},
{id:2,name:'Listar estudantes',code:'studentslist',component:StudentsListComponent,auth_required:true,type:2,departament_id:'49090a9b-1444-44cd-a999-ec76e5919e2e'},


{id:2,name:'Candidaturas',code:'candidatures',component:CandidaturesComponent,auth_required:true,type:2,departament_id:'49090a9b-1444-44cd-a999-ec76e5919e2e'},
{id:2,name:'Candidatura',code:'candidature',component:CandidaturesDetailsComponent,auth_required:true,type:2,departament_id:'49090a9b-1444-44cd-a999-ec76e5919e2e'},

{id:2,name:'Nova candidatura',code:'candidaturecreateupdate',component:CandidaturesAddUpdateComponent,auth_required:true,type:2,departament_id:'49090a9b-1444-44cd-a999-ec76e5919e2e'},
{id:2,name:'Listar candidaturas',code:'candidature-list',component:CandidaturesListComponent,auth_required:true,type:2,departament_id:'49090a9b-1444-44cd-a999-ec76e5919e2e'},


{id:2,name:'Disciplinas',code:'subjects',component:SubjectsComponent,auth_required:true,type:2,departament_id:'bf530496-2cd6-4ad0-b7ac-b561e2885cb3'},
{id:2,name:'Nova disciplinas',code:'newattrsubjects',component:SubjectsAddUpdateComponent,auth_required:true,type:1,departament_id:'bf530496-2cd6-4ad0-b7ac-b561e2885cb3'},
{id:2,name:'Plano Curricular',code:'curricularplans',component:CurricularPlanComponent,auth_required:true,type:2,departament_id:'bf530496-2cd6-4ad0-b7ac-b561e2885cb3'},
{id:2,name:'Plano Curricular',code:'curricularplanaddupdate',component:AddUpdateCurricularPlanComponent,auth_required:true,type:2,departament_id:'bf530496-2cd6-4ad0-b7ac-b561e2885cb3'},
{id:2,name:'Thema W11',code:'win11theme',component:MainWin11Component,auth_required:true,type:1,departament_id:'bf530496-2cd6-4ad0-b7ac-b561e2885cb3'},

{id:2,name:'Pagamentos',code:'payments',component:PaymentsComponent,auth_required:true,type:2,departament_id:'29698478-2886-46ff-b4ea-4dff70a3c3b4'},
{id:2,name:'Pagamentos',code:'payment-datails',component:PaymentDetailsComponent,auth_required:true,type:1,departament_id:'29698478-2886-46ff-b4ea-4dff70a3c3b4'},
{id:2,name:'Pagamentos',code:'payment-list',component:PaymentListComponent,auth_required:true,type:1,departament_id:'29698478-2886-46ff-b4ea-4dff70a3c3b4'},

{id:2,name:'Pagamentos',code:'payment-addupdate',component:AddUpdatePaymentComponent,auth_required:true,type:1,departament_id:'29698478-2886-46ff-b4ea-4dff70a3c3b4'},

]
