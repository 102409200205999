
          <div class="row formavlidation-wrapper  ">
            <div class="col-12 col-lg-12">
              <div class="accordion" id="accordionsimplefill">
                <div class="mb-2 acd-group">
                  <div class="card-header rounded-0 bg-according">
                    <h6 class="mb-0">
                      <a href="#collapse2" class="btn-block text-white text-left acd-heading collapsed"
                        data-toggle="collapse"> Filtrar dados</a>
                    </h6>
                  </div>
                  <div id="collapse2" class="collapse" data-parent="#accordionsimplefill">
                    <div class="row">
                      <div class="col-12 col-lg-12">

                        <div class="card card-statistics">
                          <div class="card-body">

                            <fieldset>
                              <div class="form-row">






                                <div class="col-md-4 col-sm-12">
                                  <div class="form-group">
                                    <label class="control-label">Morada *</label>

                                  </div>
                                </div>

                                <div class="col-md-2 col-sm-12">

                                </div>



                              </div>

                            </fieldset>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

