import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpRepoService } from 'src/app/repository/http/http/http_repo.service';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { GeneralpdfService } from 'src/app/services/pdf/generalpdf.service';

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.css']
})
export class PaymentDetailsComponent implements OnInit {
  title='Fatura de pagamento de emolumentos'
  @Input() id=''
  @Output() currentView : EventEmitter<string> = new EventEmitter()
  payData:any
  insttute:any
  logo: any;
  constructor(private http:HttpRepoService,private toastr:ToastrService,private generalPDF:GeneralpdfService) { }

  ngOnInit(): void {

  }
  ngOnChanges(changes: SimpleChanges): void {
    // idUser.currentValue
  //console.log(changes)


    this.getClass()
this.getMyInstitute()

  }

  getMyInstitute(){
    this.http.All("/institutes/my-institute").subscribe((res)=>{
     this.logo=res.data.logo
    this.insttute=res.data

    },((error)=>{
      if(error.status==401){
        this.toastr.error("Não autorizado!")
      }
     else if(error.status==403){
        this.toastr.error(error.error)
      }
      else{
        this.toastr.error(error.message)

      }    console.log(error)
    }))
  }

  async getClass(){
    this.http.All("/payments/bydocument/"+this.id).subscribe((res)=>{
        console.log(res.data)
      this.payData=res.data

      //seletedlists


    },((error)=>{
      if(error.status==401){
        this.toastr.error("Não autorizado!")
      }
     else if(error.status==403){
        this.toastr.error(error.error)
      }
      else{
        this.toastr.error(error.message)

      }    console.log(error)
    }))
  }

  backToList(code:string){
    this.currentView.emit(code);


  try{

  }catch(ex){

  }
    //$('#'+ev).toggleClass('show')
   }



pdf(data:any){
  this.generalPDF.generatePdf(data,"FATURA")
}

}
