import { Component, EventEmitter, OnInit, Output, Pipe } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpRepoService } from 'src/app/repository/http/http/http_repo.service';
import { PaginationDataService } from 'src/app/services/base/pagination-data.service';
import { SecurityDataService } from 'src/app/services/security/security-data.service';

@Component({
  selector: 'app-classes-list',
  templateUrl: './classes-list.component.html',
  styleUrls: ['./classes-list.component.css']
})
export class ClassesListComponent implements OnInit {

   @Output() currentView : EventEmitter<{}> = new EventEmitter()

  @Pipe({
    name: 'tableFilter'
  })
   currentCompnent='Turmas'
filternName=''
  classes:any
  classesBruteData:any
pages:any
totalPages=0
pageNumber=0
totalRows=0
lastPage=1
name: any;
  constructor(private toastr: ToastrService, private __security:SecurityDataService,private http:HttpRepoService,private paginate:PaginationDataService) {
    this.__security.encrypt('ola')

   }

  ngOnInit(): void {
   this. getClassesData()

  }



  addClass(code:string){
    this.currentView.emit(code);


  try{

  }catch(ex){

  }
    //$('#'+ev).toggleClass('show')
   }
   sendId(id:string,code:string){
    let data={id:id,code:code}
    this.currentView.emit(data);

  try{

  }catch(ex){

  }
    //$('#'+ev).toggleClass('show')
   }
   private getClassesData() {
    try{
      this.http.All('/classes')
      .subscribe((res)=>{
        console.log(res)

          if(res.data.length>0){

            this.classesBruteData =res.data
            this.pages=this.paginate.paginate( this.classesBruteData)
            this.classes=this.pages.items
            this.totalRows=this.pages.total
            this.totalPages= this.pages.totalPages
            console.log(this.paginate.paginate( this.classesBruteData))
/* var value = $(this).val().toLowerCase();
    $("#myList li").filter(function() {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
    });*/

          }
          else{}
      //  this.topRightToast('Sem dados!')
      },((error)=>{
        if(error.status==401){
          this.toastr.error("Não autorizado!")
        }
       else if(error.status==403){
          this.toastr.error(error.error)
        }
        else{
          this.toastr.error(error.message)

        }    console.log(error)
      }));

    }catch(ex:any){

    this.toastr.error("Erro")

    }


  }
  nextPageOrpreviousPage(){

    this.pages=this.paginate.nextPageOrpreviousPage(this.classesBruteData,this.pages.nextPage)
    this.classes=this.pages.items
    this.lastPage=this.pages.nextPage
  }
  previousPage(){
    if(this.lastPage>1){
      this.lastPage= this.lastPage-1
    }


    this.pages=this.paginate.nextPageOrpreviousPage(this.classesBruteData,this.lastPage)
    this.classes=this.pages.items
  }
  SetnextPageOrpreviousPage(_pag=0){

    this.pages=this.paginate.nextPageOrpreviousPage(this.classesBruteData,_pag)
    this.classes=this.pages.items
    console.log( this.classes)
  }
  filter(ev:any){
    console.log(ev.target.value)
let data =this.paginate.paginate(this.paginate.filterData(this.classesBruteData,ev.target.value))

//this.users= data.items
  }



}
