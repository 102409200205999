import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MainDashboardPrimaryComponent } from '../main-dashboard-primary/main-dashboard-primary.component';

@Component({
  selector: 'app-nav-bar-dashboard-primary',
  templateUrl: './nav-bar-dashboard-primary.component.html',
  styleUrls: ['./nav-bar-dashboard-primary.component.scss']
})
export class NavBarDashboardPrimaryComponent implements OnInit {

  col_10='col-md-12'
  col_2='col-md-0'
 @Output() outputFromNavBarCol_10 : EventEmitter<string> = new EventEmitter()
 @Output() outputFromNavBarCol_2 : EventEmitter<string> = new EventEmitter()
  constructor() { }

  ngOnInit(): void {
  }
  sideBar(){
    if(this.col_10=='col-md-12' && this.col_2=='col-md-0'){
      this.col_10='col-md-10'
      this.col_2='col-md-2'
      this.outputFromNavBarCol_10.emit(this.col_10)
      this.outputFromNavBarCol_2.emit(this.col_2)

    }else{
      this.col_10='col-md-12'
      this.col_2='col-md-0'

      this.outputFromNavBarCol_10.emit(this.col_10)
      this.outputFromNavBarCol_2.emit(this.col_2)
    }

  }
}
