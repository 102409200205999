

<!-- /.modal  produtos -->
<div #modalMatricula id="modal_conatainer" tyle="zoom: 90%;" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="largeModal" aria-hidden="true" style="display: none;">
  <div class="modal-dialog modal-lg {{modal_dialogo_full}} " >

    <div class="modal-content {{modal_content_full}} " id="modal_shadow">
      <section id="modal__bar">
        <div id="bar__buttons">
          <button class="bar__button" id="bar__button--exit" #closeModal
          data-dismiss="modal">&#10005;</button>
          <button class="bar__button" (click)="minimize()">&#9472;</button>
          <button class="bar__button">&#9723;</button>
        </div>
        <p id="bar__user">fobabs@ubuntu: ~</p>
      </section>
    <div class="modal-body ">
      <div class="col-xxl-12">
<app-user-add-update></app-user-add-update>
          </div>


        </div>
        <!-- start file maneger -->

