

    <!-- begin container-fluid -->
    <div class="container-fluid">
      <!-- begin row -->
      <div class="row">
          <div class="col-md-12 m-b-30">
              <!-- begin page title -->
              <div class="d-block d-lg-flex flex-nowrap align-items-center">
                  <div class="page-title mr-4 pr-4 ">
                      <h4> {{title}}</h4>
                      <p></p>

                  </div>
                  <div class="ml-auto d-flex align-items-center secondary-menu text-center">
                    <a data-toggle="modal"  *ngIf="operation=='Matrícula'" (click)="save()" class="tooltip-wrapper" title="Salvar">

                      <i class="fa fa-save btn btn-icon text-primary"></i> </a>
                    <a data-toggle="modal"  class="tooltip-wrapper" title="Adicionar Matrícula">


                    <a data-toggle="modal"  *ngIf="operation=='Candidatura'" (click)="saveCandidature()" class="tooltip-wrapper" title="Salvar Candidatura">

                      <i class="fa fa-save btn btn-icon text-primary"></i> </a>


                          <i class="fa fa-refresh btn btn-icon text-primary" (click)="refress()"></i> </a>



                      <a class="tooltip-wrapper" data-toggle="tooltip" data-placement="top" title="" data-original-title="Listar">
                          <i class="fa fa-list btn btn-icon text-primary" (click)="backToList('candidature-list')"></i> </a>
                  </div>
              </div>
              <!-- end page title -->
          </div>
      </div>


      <div class="row formavlidation-wrapper  ">
        <div class="col-12 col-lg-12">
          <div class="accordion" id="accordionsimplefill">
            <div class="mb-2 acd-group">
              <div class="card-header rounded-0 bg-according">
                <h6 class="mb-0">
                  <a href="#collapse2" class="btn-block text-white text-left acd-heading collapsed"
                    data-toggle="collapse"> Dados Pessoais</a>
                </h6>
              </div>
              <div id="collapse2" class="collapse" data-parent="#accordionsimplefill">
                <div class="row">
                  <div class="col-12 col-lg-12">

                    <div class="card card-statistics">
                      <div class="card-body">

                        <fieldset>


      <div class="row formavlidation-wrapper">

        <div class="col-md-12">
            <div class="card card-statistics">
                <div class="page-title mr-4 pr-4 " style="margin:20px 0px 0px 20px ">
                    <h6 class="text-danger"> Preencha os campos </h6>

                </div>

                <div class="card-body">
                    <div class="tab nav-border-bottom">


                        <div class="tab-content">
                            <div class="tab-pane fade py-3 active show" id="home-02" role="tabpanel" aria-labelledby="home-02-tab">
                                <div id="advanced-first" class="step">
                                    <!-- Step Info -->

                                    <div id="advanced-first" class="step">
                                        <!-- Step Info -->

                                        <div class="form-row">
                                          <div class="col-md-4 col-sm-12">
                                            <div class="form-group">
                                                <label class="control-label">Nome Completo*</label>
                                                 <input type="text" class="form-control"  [(ngModel)]="userForm.name" name="userForm.name">
                                              </div>
                                        </div>
                                        <div class="col-md-4 col-sm-12">
                                          <div class="form-group">
                                              <label class="control-label">Nome do pai*</label>
                                               <input type="text" class="form-control"  [(ngModel)]="userForm.father_name" name="userForm.father_name">
                                            </div>
                                      </div>
                                      <div class="col-md-4 col-sm-12">
                                        <div class="form-group">
                                            <label class="control-label">Nome da mãe*</label>
                                             <input type="text" class="form-control"  [(ngModel)]="userForm.mother_name" name="userForm.mother_name" >
                                          </div>
                                    </div>
                                    <div class="col-md-3 col-sm-12">
                                      <div class="form-group">
                                          <label class="control-label">Bilhete de Identidade*</label>
                                           <input type="text" class="form-control"  [(ngModel)]="userForm.identity_card" name="userForm.identity_card">
                                        </div>
                                  </div>
                                            <div class="col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label class="control-label">País de origem*</label>
                                                    <ng-multiselect-dropdown [placeholder]="' -- seleciona uma opção -- '" [settings]="dropdownSettings"
                                                    [data]="countryNames"
                                                    (onSelect)="onSelect($event,1)"
                                                      name="selectedCountry_id"
                                                   [(ngModel)]="selectedCountry_id">
                                                   </ng-multiselect-dropdown>
                                                                                         </div>
                                            </div>

                                            <div class="col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label class="control-label">Província *</label>
                                                    <ng-multiselect-dropdown [placeholder]="' -- seleciona uma opção -- '" [settings]="dropdownSettings"
                                                    [data]="provincesNames"
                                                    (onSelect)="onSelect($event,2)" name="selectedProvince_id" [(ngModel)]="selectedProvince_id">
                                                   </ng-multiselect-dropdown>
                                                </div>
                                            </div>
                                            <div class="col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label class="control-label">Município *</label>
                                                    <ng-multiselect-dropdown [placeholder]="' -- seleciona uma opção -- '" [settings]="dropdownSettings"
                                                    [data]="municipaliesNames"
                                                    (onSelect)="onSelect($event,3)"
                                                     name="seletedmunicipaliesNames" [(ngModel)]="seletedmunicipaliesNames">
                                                   </ng-multiselect-dropdown>
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-sm-12">
                                              <div class="form-group">
                                                  <label class="control-label">País de Residência*</label>
                                                   <input type="text" class="form-control"  [(ngModel)]="userForm.country" name="userForm.country">
                                                </div>
                                          </div>
                                          <div class="col-md-4 col-sm-12">
                                            <div class="form-group">
                                                <label class="control-label">Província de Residência*</label>
                                                 <input type="text" class="form-control"  [(ngModel)]="userForm.province" name="userForm.province">
                                              </div>
                                        </div>
                                          <div class="col-md-4 col-sm-12">
                                            <div class="form-group">
                                                <label class="control-label">Município de Residência*</label>
                                                 <input type="text" class="form-control"  [(ngModel)]="userForm.municipio" name="userForm.municipio">
                                              </div>
                                        </div>
                                        <div class="col-md-4 col-sm-12">
                                          <div class="form-group">
                                              <label class="control-label">Bairro de Residência*</label>
                                               <input type="text" class="form-control"  [(ngModel)]="userForm.bairro" name="userForm.bairro">
                                            </div>
                                      </div>


                                      <div class="col-md-4 col-sm-12">
                                        <div class="form-group">
                                            <label class="control-label">Telefone Principal*</label>
                                             <input type="text" class="form-control"  [(ngModel)]="userForm.phone" name="userForm.phone">
                                          </div>
                                    </div>
                                    <div class="col-md-4 col-sm-12">
                                      <div class="form-group">
                                          <label class="control-label">Telefone Alternativo*</label>
                                           <input type="text" class="form-control"  [(ngModel)]="userForm.phone_secundary" name="userForm.phone_secundary">
                                        </div>
                                  </div>

                                        </div>
                                        <div class="form-row">


                                          <div class="col-md-2 col-sm-12">
                                            <div class="form-group">
                                                <label class="control-label">Categoria*</label>
                                                <ng-multiselect-dropdown [placeholder]="' -- seleciona uma opção -- '" [settings]="dropdownSettings"
                                                [data]="typesNames"
                                                (onSelect)="onSelect($event,5)"
                                                name="seletedtypesNames" [(ngModel)]="seletedtypesNames">
                                               </ng-multiselect-dropdown>

                                            </div>
                                        </div>
                                        <div class="col-md-2 col-sm-12">
                                          <div class="form-group">
                                              <label class="control-label">Gênero *</label>
                                              <ng-multiselect-dropdown [placeholder]="' -- seleciona uma opção -- '" [settings]="dropdownSettings"
                                              [data]="genreNames"
                                              (onSelect)="onSelect($event,11)"
                                              name="seletedGenreNames" [(ngModel)]="seletedGenreNames">
                                             </ng-multiselect-dropdown>

                                          </div>
                                      </div>
                                            <div class="col-md-2 col-sm-12">
                                                <div class="form-group">
                                                    <label class="control-label">Estado civil *</label>
                                                    <ng-multiselect-dropdown [placeholder]="' -- seleciona uma opção -- '" [settings]="dropdownSettings"
                                                    [data]="maritalStatusNames"
                                                    (onSelect)="onSelect($event,9)"
                                                    name="selectedMaritalStatusNames" [(ngModel)]="selectedMaritalStatusNames">
                                                   </ng-multiselect-dropdown>

                                                </div>
                                            </div>
                                            <div class="col-md-3 col-sm-12" *ngIf="!id">
                                              <div class="form-group">
                                                  <label class="control-label">Usuário *</label>
                                                  <input required="required"  class="form-control" type="text" [(ngModel)]="userForm.email" name="userForm.email" required="" placeholder="E-mail ">

                                              </div>
                                          </div>
                                          <div class="col-md-3 col-sm-12" *ngIf="!id">
                                            <div class="form-group">
                                                <label class="control-label">Senha *</label>
                                                <input required="required"  class="form-control" type="password" [(ngModel)]="userForm.password" name="userForm.password" required="" placeholder="Senha ">

                                            </div>
                                        </div>





                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>


                        </fieldset>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>



      <div class="row formavlidation-wrapper  ">
        <div class="col-12 col-lg-12">
          <div class="accordion" id="accordionsimplefill">
            <div class="mb-2 acd-group">
              <div class="card-header rounded-0 bg-according">
                <h6 class="mb-0">
                  <a href="#collapse2Academicos" class="btn-block text-white text-left acd-heading collapsed"
                    data-toggle="collapse"> Dados Académicos</a>
                </h6>
              </div>
              <div id="collapse2Academicos" class="collapse" data-parent="#accordionsimplefill">
                <div class="row">
                  <div class="col-12 col-lg-12">

                    <div class="card card-statistics">
                      <div class="card-body">

                        <fieldset>


      <div class="row formavlidation-wrapper  ">

        <div class="col-md-12">
            <div class="card card-statistics">
                <div class="page-title mr-4 pr-4 " style="margin:20px 0px 0px 20px ">
                    <h6 class="text-danger"> Preencha os dados académicos</h6>
                </div>
                <div class="card-body">


                    <div class="form-row">
                      <div class="col-md-3 col-sm-12">
                        <div class="form-group">
                            <label class="control-label">Tipo de Iscrição*</label>
                            <ng-multiselect-dropdown [placeholder]="' -- seleciona uma opção -- '" [settings]="dropdownSettings"
                            [data]="operationTypes"
                            (onSelect)="onSelect($event,15)" name="seletedOperationTypes" [(ngModel)]="seletedOperationTypes">
                           </ng-multiselect-dropdown>
                        </div>
                    </div>
                      <div class="col-md-3 col-sm-12">
                        <div class="form-group">
                            <label class="control-label">Ano lectivo *</label>
                            <ng-multiselect-dropdown [placeholder]="' -- seleciona uma opção -- '" [settings]="dropdownSettings"
                            [data]="yearsNames"
                            (onSelect)="onSelect($event,4)"
                             name="seletedYearsNames" [(ngModel)]="seletedYearsNames">

                           </ng-multiselect-dropdown>
                        </div>
                    </div>

                    <div class="col-md-3 col-sm-12">
                      <div class="form-group">
                          <label class="control-label">Curso *</label>
                          <ng-multiselect-dropdown [placeholder]="' -- seleciona uma opção -- '" [settings]="dropdownSettings"
                          [data]="courseNames"
                          (onSelect)="onSelect($event,7)"
                           name="seletedCourseNames" [(ngModel)]="seletedCourseNames">

                         </ng-multiselect-dropdown>
                      </div>
                  </div>
                  <div class="col-md-3 col-sm-12" *ngIf="operation=='Matrícula'">
                    <div class="form-group">
                        <label class="control-label">Nível *</label>
                        <ng-multiselect-dropdown [placeholder]="' -- seleciona uma opção -- '" [settings]="dropdownSettings"
                        [data]="levelsNames"
                        (onSelect)="onSelect($event,8)"
                         name="seletedCevelsNames" [(ngModel)]="seletedCevelsNames">

                       </ng-multiselect-dropdown>
                    </div>
                </div>
                <div class="col-md-3 col-sm-12">
                  <div class="form-group">
                      <label class="control-label">Período*</label>
                      <ng-multiselect-dropdown [placeholder]="' -- seleciona uma opção -- '" [settings]="dropdownSettings"
                      [data]="periodsNames"
                      (onSelect)="onSelect($event,13)"
                      name="seletedPeriodsNames" [(ngModel)]="seletedPeriodsNames">
                     </ng-multiselect-dropdown>

                  </div>
              </div>

              <div class="col-md-3 col-sm-12">
                <div class="form-group">
                    <label class="control-label">Sala*</label>
                    <ng-multiselect-dropdown [placeholder]="' -- seleciona uma opção -- '" [settings]="dropdownSettings"
                    [data]="classromNames"
                    (onSelect)="onSelect($event,16)" name="seletedClassromNames" [(ngModel)]="seletedClassromNames">
                   </ng-multiselect-dropdown>
                </div>
            </div>
                      <div class="col-md-3 col-sm-12" *ngIf="operation=='Matrícula'">
                        <div class="form-group">

                            <label class="control-label">Turma*</label>
                            <ng-multiselect-dropdown [placeholder]="' -- seleciona uma opção -- '" [settings]="dropdownSettings"
                            [data]="classesNames"
                            (onSelect)="onSelect($event,6)"
                            name="seletedclassesNames" [(ngModel)]="seletedclassesNames">
                           </ng-multiselect-dropdown>

                        </div>
                    </div>

                    <div class="col-md-3 col-sm-12">
                      <div class="form-group">
                          <label class="control-label">Regime*</label>
                          <ng-multiselect-dropdown [placeholder]="' -- seleciona uma opção -- '" [settings]="dropdownSettings"
                          [data]="regimeNames"
                          (onSelect)="onSelect($event,10)"
                          name="selectedRegimeNames" [(ngModel)]="selectedRegimeNames">
                         </ng-multiselect-dropdown>

                      </div>
                  </div>
                  <div class="col-md-3 col-sm-12" *ngIf="operation=='Matrícula'">
                    <div class="form-group">
                        <label class="control-label">Situação Acad.*</label>
                        <ng-multiselect-dropdown [placeholder]="' -- seleciona uma opção -- '" [settings]="dropdownSettings"
                        [data]="situationNames"
                        (onSelect)="onSelect($event,12)"
                        name="selectedSituationNames" [(ngModel)]="selectedSituationNames">
                       </ng-multiselect-dropdown>

                    </div>
                </div>
                  <div class="col-md-2 col-sm-12">
                    <div class="form-group">
                        <label class="control-label">Ano conclusão anterior*</label>
                        <input required="required"  class="form-control" type="text" [(ngModel)]="userForm.conclusion_year" name="userForm.conclusion_year" required="" placeholder="Ano conclusão ">

                    </div>
                </div>

                <div class="col-md-2 col-sm-12">
                  <div class="form-group">
                      <label class="control-label">Ano de término - atual*</label>
                      <input required="required"  class="form-control" type="text" [(ngModel)]="userForm.end_year" name="userForm.end_year" required="" placeholder="Ano término atual ">

                  </div>
              </div>
                </div>

            </div>
        </div>
    </div>



</div>

                        </fieldset>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="row formavlidation-wrapper  ">
        <div class="col-12 col-lg-12">
          <div class="accordion" id="accordionsimplefill">
            <div class="mb-2 acd-group">
              <div class="card-header rounded-0 bg-according">
                <h6 class="mb-0">
                  <a href="#collapse2Cadeiras" class="btn-block text-white text-left acd-heading collapsed"
                    data-toggle="collapse"> Inscrição de disciplinas de Sequência e em atraso</a>
                </h6>
              </div>
              <div id="collapse2Cadeiras" class="collapse" data-parent="#accordionsimplefill">
                <div class="row">
                  <div class="col-12 col-lg-12">

                    <div class="card card-statistics">
                      <div class="card-body">

                        <fieldset>


      <div class="row formavlidation-wrapper  ">

        <div class="col-md-12">
            <div class="card card-statistics">
                <div class="page-title mr-4 pr-4 " style="margin:20px 0px 0px 20px ">
                    <h6 class="text-danger"> Preencha os dados</h6>
                </div>
                <div class="card-body">


                    <div class="form-row">
                      <div class="col-md-12 col-sm-12">
                        <div class="form-group">

                            <label class="control-label" (click)="mergue()">Disciplinas de sequencia*</label>
                            <i class="fa fa-plus-circle btn btn-icon text-primary" (click)="mergue()"></i>

                            <ng-multiselect-dropdown [placeholder]="' -- seleciona uma opção -- '" [settings]="dropdownSettings"
                            [data]="courseNames"
                            (onSelect)="onSelect($event,14)"
                             name="seletedCourseNames" [(ngModel)]="seletedCourseNames">

                           </ng-multiselect-dropdown>
                        </div>
                    </div>


                </div>

            </div>
        </div>
    </div>



</div>

                        </fieldset>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>



</div>



